import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default styled(Link)`
  text-decoration: none;
  color: inherit;
   
  :visited {
    text-decoration: none;
  }
`;
