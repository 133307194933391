import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Menu, { MenuProps } from '@material-ui/core/Menu';

export type SimpleMenuProps = MenuProps & {
  buttonContent: React.ReactNode,
  open: boolean,
  onOpen: () => void;
  onClose: () => void;
} & ({ buttonType?: 'normal', buttonProps?: ButtonProps } | { buttonType: 'icon', buttonProps?: IconButtonProps });

const SimpleMenu: React.FunctionComponent<SimpleMenuProps> = (props) => {
  const { children, buttonType, buttonContent, buttonProps, open, onOpen, onClose, ...menuProps } = props;
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleMenu = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  }

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  return (
    <>
    {buttonType === 'icon'
      ? <IconButton {...buttonProps as IconButtonProps} onClick={handleMenu}>{buttonContent}</IconButton>
      : <Button {...buttonProps} onClick={handleMenu}>{buttonContent}</Button>
    }
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={!!open}
      onClose={handleClose}
      disableAutoFocusItem={true}
      {...menuProps}
    >
      {children}
    </Menu>
    </>
  );
};

export default SimpleMenu;
