import React from 'react';
import { Control, Controller, Validate } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

export type ControlledTextFieldProps = {
  name: string,
  label: string,
  pattern?: RegExp,
  className?: string,
  required?: boolean,
  control: Control,
  errors: Record<string, any>,
  multiline?: boolean,
  validate?: Validate,
};

export function ControlledTextField(props: ControlledTextFieldProps) {
  const { name, label, required, pattern, control, errors, className, multiline, validate } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={{
        required,
        pattern,
        validate,
      }}
      render={(props) => (
        <TextField
          fullWidth
          margin="dense"
          multiline={multiline}
          className={className}
          label={label}
          error={!!errors[name]}
          {...props}
        />
      )}
    />
  );
}
