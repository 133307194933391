import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuery } from '../../../hooks/useQuery';
import {
  CreateTenantMutation,
  CreateTenantMutationVariables,
  PlansQuery,
  PlansQueryVariables,
} from '../../../apiTypes';
import raw from 'raw.macro';
import { Centered, CenteredHorizontally, Scrollable } from '../../../utils/ui';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '../../../hooks/useMutation';
import { useHistory } from 'react-router-dom';
import { TENANTS } from '../../../Routes';
import { getPlanLabel } from '../../../utils/plans';
import { ControlledTextField } from '../../../components/ControlledTextField';

const plansQuery = raw('../../../queries/plans.graphql');
const createTenantQuery = raw('../../../queries/createTenant.graphql');

const useStyles = makeStyles({
  wideTextField: {
    width: '100%',
    maxWidth: 200,
  },
  card: {
    maxWidth: 400,
    width: '100%',
    margin: 24,
  },
  form: {
    marginTop: 8,
  },
});


type FormData = {
  tenantName: string,
  email: string,
  firstName: string,
  lastName: string,
  plan: string,
  coupon: string,
};

const NewTenantForm = () => {
  const { control, handleSubmit, errors } = useForm<FormData>();
  const classes = useStyles();

  const history = useHistory();

  const plans = useQuery<PlansQuery, PlansQueryVariables>({
    query: plansQuery,
  });

  const [createTenant, createTenantRequest] = useMutation<CreateTenantMutation, CreateTenantMutationVariables>({
    query: createTenantQuery,
    onCompleted: () => {
      history.push(TENANTS);
    },
  });

  const onSubmit = (data: FormData) => {
    createTenant({
      variables: {
        input: {
          name: data.tenantName,
          email: data.email,
          owner: {
            firstName: data.firstName,
            lastName: data.lastName,
          },
          plan: data.plan,
          couponCode: data.coupon,
        },
      },
    });
  };

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent>
        <Typography style={{ fontSize: 14}} gutterBottom>
          New Tenant
        </Typography>
        {(plans.loading || createTenantRequest.loading) && (
          <Centered>
            <CircularProgress />
          </Centered>
        )}
        {plans.error && (
          <h6>Error</h6>
        )}
        {createTenantRequest.error && <Typography color="error">Error creating tenant</Typography>}
        {!createTenantRequest.loading && plans.data && (
          <form id='new-tenant-form' className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ControlledTextField
                  name="tenantName"
                  label="Tenant Name"
                  control={control}
                  errors={errors}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  name="email"
                  label="Email"
                  control={control}
                  errors={errors}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  name="firstName"
                  label="First Name"
                  control={control}
                  errors={errors}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  name="lastName"
                  label="Last Name"
                  control={control}
                  errors={errors}
                  required
                />
              </Grid>
              <Grid item xs={7}>
                <Controller
                  name="plan"
                  control={control}
                  rules={{
                    required: true
                  }}
                  defaultValue=""
                  render={(props) => (
                    <FormControl fullWidth margin="dense">
                      <InputLabel required error={!!errors.plan}>Plan</InputLabel>
                      <Select
                        error={!!errors.plan}
                        {...props}
                      >
                        {plans.data!.plans.map((plan) => (
                          <MenuItem key={plan.id} value={plan.id}>{getPlanLabel(plan)}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <ControlledTextField
                  name="coupon"
                  label="Coupon"
                  control={control}
                  errors={errors}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </CardContent>
      {!createTenantRequest.loading && plans.data && (
        <CardActions>
          <Button
            type="submit"
            color="primary"
            form="new-tenant-form"
          >
            Submit
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

export default () => {
  return (
    <Scrollable>
      <CenteredHorizontally>
        <NewTenantForm />
      </CenteredHorizontally>
    </Scrollable>
  )
};
