import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { AsyncTaskProvider } from './features/asyncTasks/store';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import MomentUtils from '@date-io/moment';
import Root from './Root';

function App() {
  return (
    <Auth0Provider
      domain="accounts.starchive.io"
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_API_AUDIENCE}
      scope={process.env.REACT_APP_API_SCOPE}
    >
      <AsyncTaskProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Root />
        </MuiPickersUtilsProvider>
      </AsyncTaskProvider>
    </Auth0Provider>
  );
}

export default App;
