import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import MainLayout from '../../components/MainLayout';
import User from './User';

export default () => {
  const { path } = useRouteMatch();

  return (
    <MainLayout title="Users">
      <Switch>
        <Route path={`${path}/:tenantId/:userId`}>
          <User />
        </Route>
      </Switch>
    </MainLayout>
  );
};
