import React from 'react';
import MainLayout from '../../components/MainLayout';
import { useQuery } from '../../hooks/useQuery';
import raw from 'raw.macro';
import {
  Plan,
  SubscriptionOverviewQuery,
  SubscriptionOverviewQueryVariables,
  TenantsCountQueryVariables,
  TenantsOverviewQuery,
} from '../../apiTypes';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { CenteredHorizontally } from '../../utils/ui';
import StackedBar from '../../components/StackedBar';
import colors from 'material-colors/dist/colors.json';
import { TENANTS } from '../../Routes';
import { Link } from 'react-router-dom';
import PropertyList from '../../components/PropertyList';
import { getPlanLabel } from '../../utils/plans';
import UnderlinedLink from '../../components/UnderlinedLink';
import DashboardToolbar from "./DashboardToolbar";

const tenantsOverviewQuery = raw('../../queries/tenantsOverview.graphql');
const subscriptionOverviewQuery = raw('../../queries/subscriptionOverview.graphql');

const statusLink = (status: string) => `${TENANTS}?query=${encodeURIComponent(`status:${status}`)}`;

const TenantStatusOverview = () => {
  const request = useQuery<TenantsOverviewQuery, TenantsCountQueryVariables>({
    query: tenantsOverviewQuery,
  });

  let content: React.ReactNode = null;

  if (request.loading) {
    content = (
      <CenteredHorizontally>
        <CircularProgress />
      </CenteredHorizontally>
    );
  } else if (request.error) {
    content = 'Error';
  } else if (request.data) {
    const { active, trialing, freemium, subscribed, inactive, expired, paymentFailed, canceled, disabled } = request.data;
    const trialingColor = colors.yellow['700'];
    const freemiumColor = colors.lime['700'];
    const subscribedColor = colors.green['700'];
    const expiredColor = colors.orange['700'];
    const paymentFailedColor = colors.red['700'];
    const canceledColor = colors.red['200'];
    const disabledColor = colors.grey['700'];
    content = (
      <>
        <Typography variant='h6' gutterBottom>{active.totalCount} Active</Typography>
        <StackedBar
          data={[
            { value: trialing.totalCount, color: trialingColor },
            { value: freemium.totalCount, color: freemiumColor },
            { value: subscribed.totalCount, color: subscribedColor },
          ]}
        />
        <Grid container style={{ textAlign: 'center', marginTop: 16, marginBottom: 16 }}>
          <Grid item xs={4}>
            <Link
              style={{ color: trialingColor }}
              to={statusLink('trialing')}
            >
              <Typography
                variant='body1'
              >
                {trialing.totalCount}<br />Trialing
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link
              style={{ color: freemiumColor }}
              to={statusLink('freemium')}
            >
              <Typography
                variant='body1'
              >
                {freemium.totalCount}<br />Freemium
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link
              style={{ color: subscribedColor }}
              to={statusLink('active')}
            >
              <Typography
                variant='body1'
              >
                {subscribed.totalCount}<br />Subscribed
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Typography variant='h6' gutterBottom>{inactive.totalCount} inactive</Typography>
        <StackedBar
          data={[
            { value: expired.totalCount, color: expiredColor },
            { value: paymentFailed.totalCount, color: paymentFailedColor },
            { value: canceled.totalCount, color: canceledColor },
            { value: disabled.totalCount, color: disabledColor },
          ]}
        />
        <Grid container style={{ textAlign: 'center', marginTop: 16 }}>
          <Grid item xs={3}>
            <Link
              style={{ color: expiredColor }}
              to={statusLink('expired')}
            >
              <Typography
                variant='body1'
              >
                {expired.totalCount}<br />Expired
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Link
              style={{ color: paymentFailedColor }}
              to={statusLink('payment_failed')}
            >
              <Typography
                variant='body1'
              >
                {paymentFailed.totalCount}<br />Payment Failed
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Link
              style={{ color: canceledColor }}
              to={statusLink('canceled')}
            >
              <Typography
                variant='body1'
              >
                {canceled.totalCount}<br />Canceled
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Link
              style={{ color: disabledColor }}
              to={statusLink('disabled')}
            >
              <Typography
                variant='body1'
              >
                {disabled.totalCount}<br />Disabled
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid item xs={4}>
        <Card variant="outlined">
          <CardContent>
            <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
              Status Overview
            </Typography>
            {content}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

const SubscriptionOverview = () => {
  const request = useQuery<SubscriptionOverviewQuery, SubscriptionOverviewQueryVariables>({
    query: subscriptionOverviewQuery,
  });

  let content: React.ReactNode = null;

  if (request.loading) {
    content = (
      <CenteredHorizontally>
        <CircularProgress />
      </CenteredHorizontally>
    );
  } else if (request.error) {
    content = 'Error';
  } else if (request.data) {
    const { plans } = request.data;
    const sorted = plans.sort((a, b) => b.tenants.totalCount - a.tenants.totalCount);
    const active = sorted.filter((plan) => plan.isActive);
    const inactive = sorted.filter((plan) => plan.tenants.totalCount && !plan.isActive);
    const getLabel = (plan: Pick<Plan, 'stripeId' | 'stripeName' | 'name' | 'interval'>) => (
      <UnderlinedLink to={`${TENANTS}?query=${encodeURIComponent(`status:active plan:${plan.stripeId}`)}`}>
        {plan.stripeName || getPlanLabel(plan)}
      </UnderlinedLink>
    );
    content = (
      <>
        <Typography gutterBottom>Current</Typography>
        <PropertyList
          data={active.map((plan) => ({
            label: getLabel(plan),
            value: plan.tenants.totalCount,
          }))}
        />
        <Typography gutterBottom style={{ marginTop: 16 }}>Other</Typography>
        <PropertyList
          data={inactive.map((plan) => ({
            label: getLabel(plan),
            value: plan.tenants.totalCount,
          }))}
        />
      </>
    );
  }

  return (
    <>
      <Grid item xs={4}>
        <Card variant="outlined">
          <CardContent>
            <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
              Subscription Overview
            </Typography>
            {content}
          </CardContent>
        </Card>
      </Grid>
    </>
  )
};

export default () => (
  <MainLayout title="Dashboard">
    <DashboardToolbar />
    <Grid container spacing={3} style={{ padding: 16 }}>
      <TenantStatusOverview />
      <SubscriptionOverview />
    </Grid>
  </MainLayout>
);
