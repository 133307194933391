import { PlansQuery } from '../../../apiTypes';
import { getPlanLabel } from '../../../utils/plans';
import { Maybe } from '../../../types';

export const searchFilters = ['status', 'email', 'emailNot', 'active', 'plan', 'coupon'];

const filterRegexp = (filter: string) => new RegExp(`(^${filter}| ${filter}):$`);

export const getAutocompleteOptions = (value: string, plans?: Maybe<PlansQuery['plans']>): Array<{ label: string, value: string, disabled?: boolean }> => {
  const returnVal = (list: string[]) => list.map((label) => ({
    label,
    value: ([value, label].filter((v) => v).join('')) || '',
  }));

  if (!value.length || value.endsWith(' ')) {
    return returnVal(searchFilters.map((word) => `${word}:`).filter((word) => {
      if (word === 'email:' && value.match(/emailNot:/g)) {
        return false;
      }
      if (word === 'emailNot' && value.match(/email:/g)) {
        return false;
      }
      return !value.match(new RegExp(word, 'g'));
    }));
  }

  if (value.match(filterRegexp('status'))) {
    return returnVal([
      'trialing',
      'freemium',
      'active',
      'expired',
      'payment_failed',
      'canceled',
      'disabled',
    ]);
  }

  if (value.match(filterRegexp('active'))) {
    return returnVal([
      'true',
      'false',
    ]);
  }

  if (value.match(filterRegexp('plan'))) {
    if (!plans || !plans.length) {
      return [{
        label: 'Loading...',
        value: 'Loading...',
        disabled: true,
      }];
    }

    return plans.map((plan) => ({
      label: getPlanLabel(plan),
      value: [value, plan.stripeId].filter((v) => v).join(''),
    }));
  }

  return [];
}
