import React, { createContext, useReducer, useContext, useEffect } from 'react';

export enum AsyncTaskStatus {
  Waiting,
  InProgress,
  Success,
  Error,
}

type AsyncTaskId = string;

export type AsyncTask = {
  status: AsyncTaskStatus,
  label: string,
  id: AsyncTaskId,
  task: () => Promise<any>,
};

export type AsyncTaskState = {
  promise: Promise<any>,
  tasks: AsyncTask[],
  queue: AsyncTaskId[],
};

export type AsyncTaskAction =
  { type: 'ADD_TASK', payload: { label: string, id: AsyncTaskId, task: () => Promise<any> } } |
  { type: 'SET_IN_PROGRESS', payload: { id: AsyncTaskId } } |
  { type: 'SET_SUCCESS', payload: { id: AsyncTaskId } } |
  { type: 'SET_ERROR', payload: { id: AsyncTaskId } } |
  { type: 'RETRY', payload: { id: AsyncTaskId } } |
  { type: 'REMOVE_TASKS', payload: { ids: AsyncTaskId[] } };

const initialState: AsyncTaskState = {
  promise: Promise.resolve(),
  tasks: [],
  queue: [],
};

export const reducer: React.Reducer<AsyncTaskState, AsyncTaskAction> = (state, action) => {
  switch (action.type) {
    case 'ADD_TASK':
      return {
        ...state,
        tasks: [...state.tasks, {
          task: action.payload.task,
          id: action.payload.id,
          label: action.payload.label,
          status: AsyncTaskStatus.Waiting,
        }],
        queue: [...state.queue, action.payload.id],
      };
    case 'SET_IN_PROGRESS':
      return {
        ...state,
        tasks: state.tasks.map((task) => ({
          ...task,
          ...task.id === action.payload.id && { status: AsyncTaskStatus.InProgress },
        })),
      }
    case 'SET_SUCCESS':
      return {
        ...state,
        tasks: state.tasks.map((task) => ({
          ...task,
          ...task.id === action.payload.id && { status: AsyncTaskStatus.Success },
        })),
        queue: state.queue.filter((id) => id !== action.payload.id),
      };
    case 'SET_ERROR':
      return {
        ...state,
        tasks: state.tasks.map((task) => ({
          ...task,
          ...task.id === action.payload.id && { status: AsyncTaskStatus.Error },
        })),
        queue: state.queue.filter((id) => id !== action.payload.id),
      };
    case 'RETRY':
      return {
        ...state,
        tasks: state.tasks.map((task) => ({
          ...task,
          ...task.id === action.payload.id && { status: AsyncTaskStatus.Waiting },
        })),
        queue: [...state.queue, action.payload.id],
      }
    case 'REMOVE_TASKS':
      return {
        ...state,
        tasks: state.tasks.filter((task) => !action.payload.ids.includes(task.id)),
      };
    default:
      return state;
  }
};

type AsyncTaskActions = {
  addTask: (label: string, task: () => Promise<any>) => void,
  removeTasks: (ids: AsyncTaskId[]) => void,
  retry: (id: AsyncTaskId) => void,
};

export const useActions = (state: AsyncTaskState, dispatch: React.Dispatch<AsyncTaskAction>): AsyncTaskActions => ({
  addTask: (label: string, task: () => Promise<any>) => {
    dispatch({
      type: 'ADD_TASK',
      payload: {
        label,
        task,
        id: Math.random().toString(36).substr(2, 9),
      },
    });
  },
  removeTasks: (ids: AsyncTaskId[]) => {
    dispatch({
      type: 'REMOVE_TASKS',
      payload: { ids },
    });
  },
  retry: (id: AsyncTaskId) => {
    dispatch({
      type: 'RETRY',
      payload: { id },
    });
  },
});

const useSideEffects = (state: AsyncTaskState, dispatch: React.Dispatch<AsyncTaskAction>) => {
  useEffect(() => {
    const inProgress = state.tasks.filter(({ status }) => status === AsyncTaskStatus.InProgress).length > 0;
    if (inProgress) {
      return;
    }

    const hasNext = state.queue.length > 0;
    if (!hasNext) {
      return;
    }

    const nextId = state.queue[0];
    const next = state.tasks.find((task) => task.id === nextId);

    if (!next) {
      throw new Error('Invalid state');
    }

    (async () => {
      const payload = { id: nextId };
      dispatch({ type: 'SET_IN_PROGRESS', payload });
      try {
        await next.task();
        dispatch({ type: 'SET_SUCCESS', payload });
      } catch (e) {
        console.error(e);
        dispatch({ type: 'SET_ERROR', payload });
      }
    })();
  });
};

export type AsyncTaskContextValue = {
  asyncTaskState: { tasks: AsyncTask[] },
  asyncTaskActions: AsyncTaskActions,
};

const AsyncTaskContext = createContext<AsyncTaskContextValue>({
  asyncTaskState: initialState,
  asyncTaskActions: { addTask: () => {}, removeTasks: () => {}, retry: () => {} },
});

export const useAsyncTasks = () => useContext(AsyncTaskContext);

export const AsyncTaskProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(state, dispatch);

  useSideEffects(state, dispatch);

  return (
    <AsyncTaskContext.Provider value={{ asyncTaskState: { tasks: state.tasks }, asyncTaskActions: actions }} >
      {children}
    </AsyncTaskContext.Provider>
  );
}

