import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Anchor, { AnchorProps } from './Anchor';

const ExternalLink: React.FunctionComponent<AnchorProps> = ({ children, ...props }) => (
  <Anchor {...props} target="_blank">
    {children}{' '}<OpenInNewIcon color="inherit" fontSize="inherit" style={{ verticalAlign: 'text-bottom' }} />
  </Anchor>
);

export default ExternalLink;
