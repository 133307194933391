import { useQuery, UseQueryOptions, UseQueryRun, UseQueryValue } from './useQuery';

export type UseMutationOptions<TData, TVariables> = Omit<UseQueryOptions<TData, TVariables>, 'manual'>

export const useMutation = <
  TData extends Record<string, any> = Record<string, any>,
  TVariables extends Record<string, any> = Record<string, any>,
>(options: UseMutationOptions<TData, TVariables>): [UseQueryRun<TVariables>, UseQueryValue<TData, TVariables>] => {
  const query = useQuery<TData, TVariables>({
    ...options,
    manual: true,
  });
  return [query.run, query];
}
