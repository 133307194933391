import React, { useState } from 'react';
import Layout from './Layout';
import MainDrawer from './MainDrawer';
import MainAppBar from './MainAppBar';

export type MainLayoutProps = {
  title: string,
};

const MainLayout: React.FunctionComponent<MainLayoutProps> = ({ children, title }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <Layout
      direction="horizontal"
      leadingComponent={(
        <MainDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      )}
    >
      <Layout
        leadingComponent={(
          <MainAppBar title={title} onMenuClick={() => setDrawerOpen(!drawerOpen)} />
        )}
      >
        {children}
      </Layout>
    </Layout>
  );
}

export default MainLayout;
