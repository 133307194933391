import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Centered } from '../../utils/ui';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoginIfNeeded: React.FunctionComponent = ({ children }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
  }

  if (isLoading || !isAuthenticated) {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    );
  }

  return <>{children}</>;
};

export default LoginIfNeeded;
