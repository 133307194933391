import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export type TextInputDialogProps = {
  open: boolean,
  title: string,
  onConfirm: (text: string) => void,
  onClose: () => void,
  fieldLabel: string,
  actionLabel: string,
};

export default (props: TextInputDialogProps) => {
  const [value, setValue] = useState('');

  const handleConfirm = () => {
    if (!!value) {
      props.onConfirm(value);
    }
  }

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        <TextField
          label={props.fieldLabel}
          autoFocus
          margin="dense"
          fullWidth
          onChange={(event) => setValue(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button style={{ color: 'gray' }} onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={handleConfirm}
          color='primary'
          disabled={!value}
        >{props.actionLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};
