import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Tenants from './features/tenants';
import Users from './features/users';
import Logout from './features/auth/Logout';
import NotFound from './features/notFound';
import Dashboard from './features/dashboard';

export const DASHBOARD = '/dashboard';
export const TENANTS = '/tenants';
export const USERS ='/users';
export const LOGOUT = '/logout';

export default () => (
  <Router>
    <Switch>
      <Route exact path="/">
        <Redirect to={DASHBOARD} />
      </Route>
      <Route path={DASHBOARD} >
        <Dashboard />
      </Route>
      <Route path={TENANTS}>
        <Tenants />
      </Route>
      <Route path={USERS}>
        <Users />
      </Route>
      <Route path={LOGOUT}>
        <Logout />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  </Router>
);
