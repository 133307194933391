import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import DrawerLink from './DrawerLink';
import styled from 'styled-components';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

export type MainDrawerProps = {
  open: boolean,
  onClose: () => void;
};

const drawerItem = (label: string, link: string) => ({ label, link });
const drawerItems = [
  drawerItem('Dashboard', '/dashboard'),
  drawerItem('Tenants', '/tenants'),
];

const Logo = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

const MainDrawer: React.FunctionComponent<MainDrawerProps> = ({ open, onClose }) => {
  const classes = useStyles();

  const drawer = (
    <div>
      <Toolbar className={classes.toolbar}>
        <Logo src="https://s3.amazonaws.com/artifacts.digitalrelab.com/web/logo-256.png" />
        <Typography variant="h6">
          Admin
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {drawerItems.map((item) => (
          <DrawerLink to={item.link} key={item.label}>
            <ListItem button key={item.label}>
              <ListItemText primary={item.label} />
            </ListItem>
          </DrawerLink>
        ))}
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          open={open}
          onClose={onClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default MainDrawer;
