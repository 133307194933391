import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export type AnchorProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

const useStyles = makeStyles((theme) => ({
  anchor: {
    ':visited': {
      color: theme.palette.primary.main,
    },
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}))

const Anchor: React.FunctionComponent<AnchorProps> = (props) => {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <a className={[classes.anchor, props.className].join(' ')} {...rest}>{children}</a>
  );
};

export default Anchor;
