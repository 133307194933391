import React from 'react';
import styled from 'styled-components';

export type StackedBarData = {
  value: number,
  color: string,
};

export type StackedBarProps = {
  data: StackedBarData[],
  direction?: 'horizontal' | 'vertical',
  size?: number,
};

type ContainerProps = Pick<StackedBarProps, 'direction' | 'size'>

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction === 'vertical' ? 'column' : 'row'};
  ${({ direction, size }) => direction === 'vertical' 
    ? `width: ${size || 40}px` 
    : `height ${size || 40}px`
  };
`;

type SectionProps = {
  color: string,
  value: number,
  direction?: 'horizontal' | 'vertical',
};

const Section = styled.div<SectionProps>`
  background-color: ${({ color }) => color};
  ${({ direction }) => direction === 'vertical'
    ? 'width: 100%'
    : 'height: 100%'
  };
  flex: ${({ value }) => value} 0 0;
`;

const StackedBar: React.FunctionComponent<StackedBarProps> = (props) => {
  return (
    <Container {...props}>
      {props.data.map((data, i) => (
        <Section {...data} direction={props.direction} key={i} />
      ))}
    </Container>
  );
};

export default StackedBar;
