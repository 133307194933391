import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export type DestructiveConfirmationDialogProps = {
  open: boolean,
  message?: string,
  onConfirm: () => void,
  onClose: () => void,
  actionLabel: string,
};

export default (props: DestructiveConfirmationDialogProps) => {
  const handleConfirm = () => {
    props.onConfirm();
  }

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        <DialogContentText>{props.message || 'Are you sure you want to continue?'}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: 'gray' }} onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={handleConfirm}
          color="primary"
        >{props.actionLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};
