import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Maybe } from '../../../types';
import { useMutation } from '../../../hooks/useMutation';
import {
  CreateAccessTokenMutation,
  CreateAccessTokenMutationVariables,
} from '../../../apiTypes';
import raw from 'raw.macro';
import { Centered } from '../../../utils/ui';
import TextField from '@material-ui/core/TextField';

const createAccessTokenQuery = raw('../../../queries/createAccessToken.graphql');

type CreateAccessTokenDialogPresentationProps = {
  loading: boolean,
  error: Maybe<string>,
  onSubmit: (title: string) => void,
  onClose: () => void,
  onSuccess: () => void,
  open: boolean,
  token: Maybe<string>,
}

export type CreateAccessTokenDialogProps = {
  tenantId: string,
  userId: string,
  onClose: () => void,
  onSuccess: () => void,
  open: boolean,
};

const CreateAccessTokenDialogPresentation = (props: CreateAccessTokenDialogPresentationProps) => {
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    if (props.token) {
      setTitle('');
    }
  }, [props.token]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTitle(event.target.value);
  };

  const canSubmit = !!title;

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Create Access Token</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        {!!props.error && <DialogContentText color="error">{props.error}</DialogContentText>}
        {props.loading && (
          <Centered>
            <CircularProgress />
          </Centered>
        )}
        {!props.loading && !props.token && (
          <FormGroup row>
            <TextField
              fullWidth
              margin="dense"
              label="Title"
              value={title}
              onChange={handleTitleChange}
            />
          </FormGroup>
        )}
        {props.token && (
          <>
            <Typography variant="body1">This will only be shown once</Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: 'monospace',
                wordWrap: 'break-word',
                marginTop: 8,
                borderRadius: 4,
                padding: 4,
                backgroundColor: '#eee',
              }}
            >
              {props.token}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!props.token && <Button style={{ color: 'gray' }} onClick={props.onClose}>Cancel</Button>}
        {!props.token && <Button
          disabled={!canSubmit}
          color={canSubmit ? 'primary' : 'default'}
          onClick={() => canSubmit && props.onSubmit(title)}
        >
          Submit
        </Button>}
        {props.token && <Button color='primary' onClick={props.onSuccess}>Done</Button>}
      </DialogActions>
    </Dialog>
  )
};

export default (props: CreateAccessTokenDialogProps) => {
  const [createAccessToken, { loading, error, data, clearData }] = useMutation<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>({
    query: createAccessTokenQuery,
    tenantId: props.tenantId,
    userId: props.userId,
  });

  return (
    <CreateAccessTokenDialogPresentation
      open={props.open}
      loading={loading}
      error={error && 'Error'}
      onSubmit={(title) => {
        createAccessToken({
          variables: {
            input: { title },
          },
        });
      }}
      token={data?.createAccessToken.token}
      onSuccess={() => {
        clearData();
        props.onSuccess();
      }}
      onClose={() => {
        props.onClose();
      }}
    />
  );
}
