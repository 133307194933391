import { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getApiToken } from '../utils/getApiToken';
import { apiRequest } from '../utils/apiRequest';
import { Maybe } from '../types';

export type UseQueryOptions<TData, TVariables> = RequestInit & {
  query: string,
  tenantId?: string,
  userId?: string,
  variables?: TVariables,
  manual?: boolean,
  onCompleted?: (data: TData) => void,
  onError?: (error: Error) => void,
};

export type UseQueryState<TData> = {
  error: Maybe<Error>,
  loading: boolean,
  data: Maybe<TData>,
};

export type UseQueryRunOptions<TVariables> = {
  variables?: Maybe<TVariables>,
};

export type UseQueryRun<TVariables> = (options?: UseQueryRunOptions<TVariables>) => void;

export type UseQueryValue<TData, TVariables> = UseQueryState<TData> & {
  run: UseQueryRun<TVariables>,
  clearData: () => void,
};

export const useQuery = <
  TData extends Record<string, any> = Record<string, any>,
  TVariables extends Record<string, any> = Record<string, any>,
>(options: UseQueryOptions<TData, TVariables>): UseQueryValue<TData, TVariables> => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState<UseQueryState<TData>>({
    error: null,
    loading: false,
    data: null,
  });
  const firstRun = useRef(true);

  const [refreshIndex, setRefreshIndex] = useState(0);
  const [variables, setVariables] = useState<Maybe<TVariables>>(null);

  const dependencies = options.manual ? [refreshIndex] : [JSON.stringify(options), refreshIndex];

  useEffect(() => {
    if (options.manual && firstRun.current) {
      firstRun.current = false;
      return;
    }

    let isCanceled = false;

    (async () => {
      setState({
        data: null,
        loading: true,
        error: null,
      });
      try {
        const accessToken = await getApiToken(getAccessTokenSilently);
        if (isCanceled) {
          return;
        }
        const data = await apiRequest<TData, TVariables>({
          ...options,
          variables: variables || options.variables,
          accessToken,
        });
        if (isCanceled) {
          return;
        }
        setState({
          ...state,
          data,
          error: null,
          loading: false,
        });
        if (options.onCompleted) {
          options.onCompleted(data);
        }
      } catch (error) {
        if (isCanceled) {
          return;
        }
        setState({
          ...state,
          error,
          loading: false,
        });
        if (options.onError) {
          options.onError(error);
        }
      }
    })();

    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return {
    ...state,
    run: (options: { variables?: Maybe<TVariables> } = {}) => {
      const { variables } = options;
      if (variables) {
        setVariables(variables);
      }
      setRefreshIndex(refreshIndex + 1);
    },
    clearData: () => {
      setState((s) => ({
        ...s,
        data: null,
      }));
    }
  };
};
