import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import raw from 'raw.macro';
import { useQuery, UseQueryValue } from '../../../hooks/useQuery';
import {
  TenantContentQuery,
  TenantContentQueryVariables,
  TenantQuery,
  TenantQueryVariables,
  TenantUsersQuery,
  TenantUsersQueryVariables,
} from '../../../apiTypes';
import { Centered, Scrollable } from '../../../utils/ui';
import Layout from '../../../components/Layout';
import PropertyList from '../../../components/PropertyList';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import { HUMAN_FULL_DATE_FORMAT } from '../../../utils/constants';
import { enumToTitle } from '../../../utils/enumToTitle';
import ExternalLink from '../../../components/ExternalLink';
import TenantToolbar from './TenantToolbar';
import DataTable, { DataTableColumn } from '../../../components/DataTable';
import { Maybe } from '../../../types';
import filesize from 'filesize';
import { USERS } from '../../../Routes';

const tenantQuery = raw('../../../queries/tenant.graphql');
const contentQuery = raw('../../../queries/tenantContent.graphql');
const usersQuery = raw('../../../queries/tenantUsers.graphql');

const Content = styled.div`
  padding: 24px;
`;

const ListContainer = styled.div<{ border?: boolean }>`
  padding-right: 12px;
  ${({ border }) => border ? 'border-right: 1px solid lightgrey;' : '' }
`;

const ListTitle = styled(({ children }) => <Typography color="textPrimary" gutterBottom>{children}</Typography>)`
  font-size: 14px;
`;

type TenantInfoProps = {
  tenantQuery: TenantQuery,
  contentRequest: UseQueryValue<TenantContentQuery, any>,
}

const TenantInfo = ({ tenantQuery, contentRequest }: TenantInfoProps) => {
  const { tenant } = tenantQuery;

  const aggregations = contentRequest.data && contentRequest.data.search.aggregations;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <ListContainer border>
          <ListTitle>General</ListTitle>
          <PropertyList
            data={[
              { label: 'Name', value: tenant.name },
              { label: 'Owner Email', value: tenant.owner.email },
              { label: 'Created On', value: moment(tenant.createdOn).format(HUMAN_FULL_DATE_FORMAT) },
              { label: 'API ID', value: tenant.id },
              { label: 'Database ID', value: tenant.databaseId },
            ]}
          />
        </ListContainer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ListContainer>
          <ListTitle>Subscription</ListTitle>
          <PropertyList
            data={[
              { label: 'Plan', value: tenant.subscription.plan.name },
              { label: 'Status', value: enumToTitle(tenant.status) },
              { label: 'Trial', value: tenant.subscription.isFreeTrial ? 'Yes' : 'No' },
              { label: 'Billing Interval', value: enumToTitle(tenant.subscription.plan.interval) },
              {
                label: 'Next Charge',
                value: tenant.subscription.nextCharge && moment(tenant.subscription.nextCharge).format(HUMAN_FULL_DATE_FORMAT),
                nullValue: 'N/A',
              },
              { label: 'Period End', value: moment(tenant.subscription.periodEnd).format(HUMAN_FULL_DATE_FORMAT) },
              { label: 'Coupon', value: tenant.subscription.discount?.coupon?.code, nullValue: 'No coupon' },
              { label: 'Seats', value: tenant.capacities.seats },
              { label: 'Seats Filled', value: tenant.capacities.seatsFilled },
              { label: 'Paid Users', value: tenant.capacities.paidUsers },
              { label: 'Included Storage', value: tenant.capacities.includedStorage.formatted },
              { label: 'Max Storage', value: tenant.capacities.maxStorage?.formatted || 'N/A' },
              ...tenant.stripeCustomer ? [{
                label: 'Stripe Customer',
                value: (
                  <ExternalLink
                    href={`https://dashboard.stripe.com/${process.env.REACT_APP_APP_ENV !== 'production' ? 'test/' : ''}customers/${tenant.stripeCustomer}`}
                  >
                    {tenant.stripeCustomer}
                  </ExternalLink>
                ),
              }] : [],
            ]}
          />
        </ListContainer>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ListContainer border>
          <ListTitle>Meta</ListTitle>
          {!Object.keys(tenant.meta).length
            ? <Typography color="textSecondary">None</Typography>
            : (<PropertyList
              data={Object.keys(tenant.meta)
                .map((key) => ({
                  label: key,
                  value: tenant.meta[key] != null
                    ? typeof tenant.meta[key] === 'string' ? tenant.meta[key] : JSON.stringify(tenant.meta[key])
                    : null,
                  nullValue: 'Not set'
                }))}
            />)
          }
        </ListContainer>
      </Grid>
      <Grid item xs={12} sm={6}>
        {!contentRequest.data
          ? <Centered><CircularProgress /></Centered>
          : (
            <ListContainer>
              <ListTitle>Content</ListTitle>
              <PropertyList
                data={[
                  { label: 'File Count', value: aggregations!.count },
                  { label: 'Total File Size', value: filesize(aggregations!.size) },
                  { label: 'Last Created', value: aggregations!.lastCreated ? moment(aggregations!.lastCreated).format(HUMAN_FULL_DATE_FORMAT) : 'N/A' },
                  { label: 'Last Modified', value: aggregations!.lastModified ? moment(aggregations!.lastModified).format(HUMAN_FULL_DATE_FORMAT) : 'N/A' },
                  { label: 'Deleted File Count', value: contentRequest.data.deleted.totalCount },
                ]}
              />
            </ListContainer>
          )
        }
      </Grid>
    </Grid>
  );
};

export type UserData = TenantUsersQuery['users']['nodes'][0] & { name: string };

type UserListData = {
  users: UserData[],
  endCursor?: Maybe<string>,
  hasNextPage: boolean,
};

const columnStyle: React.CSSProperties = {
  whiteSpace: 'nowrap',
};

const userColumns: Array<DataTableColumn<UserData>> = [
  { key: 'name', label: 'Name', style: columnStyle },
  { key: 'email', label: 'Email', style: columnStyle },
  { key: 'status', label: 'Status', style: columnStyle },
  { key: 'createdOn', label: 'Created On', style: columnStyle, render: ({ createdOn }) => moment(createdOn).format(HUMAN_FULL_DATE_FORMAT) },
  { key: 'joinedOn', label: 'Joined On', style: columnStyle, render: ({ joinedOn }) => joinedOn && moment(joinedOn).format(HUMAN_FULL_DATE_FORMAT) },
  { key: 'id', label: 'API ID', style: columnStyle },
  { key: 'databaseId', label: 'Database ID', style: columnStyle },
];

const getKey = ({ id }: UserData) => id;
const rowLink = ({ id, tenant }: UserData) => `${USERS}/${tenant.id}/${id}`

export default () => {
  const { tenantId } = useParams();
  const [userListData, setUserListData] = useState<UserListData>({
    users: [],
    hasNextPage: true,
  });

  const tenantRequest = useQuery<TenantQuery, TenantQueryVariables>({
    tenantId,
    query: tenantQuery,
  });

  const contentRequest = useQuery<TenantContentQuery, TenantContentQueryVariables>({
    tenantId,
    query: contentQuery,
  });

  const userListLoading = useRef(false);

  const usersRequest = useQuery<TenantUsersQuery, TenantUsersQueryVariables>({
    query: usersQuery,
    manual: true,
    tenantId,
    onCompleted: (result) => {
      setUserListData((data) => ({
        users: [...data.users, ...result.users.nodes.map((node) => ({
          ...node,
          name: `${node.firstName} ${node.lastName}`,
        }))],
        endCursor: result.users.pageInfo.endCursor || data.endCursor,
        hasNextPage: result.users.pageInfo.hasNextPage,
      }));
      userListLoading.current = false;
    }
  });

  if (tenantRequest.loading) {
    return <Centered><CircularProgress /></Centered>;
  }

  if (tenantRequest.error) {
    return <h1>Error</h1>;
  }

  if (tenantRequest.data) {
    return (
      <Layout
        leadingComponent={(
          <TenantToolbar
            tenant={tenantRequest.data.tenant}
            onRefresh={() => tenantRequest.run()}
          />
        )}
      >
        <Scrollable>
          <Content>
            <TenantInfo
              tenantQuery={tenantRequest.data}
              contentRequest={contentRequest}
            />
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ListContainer>
                  <ListTitle>Users</ListTitle>
                  <div style={{ height: 400, border: '1px solid lightgrey' }}>
                    <DataTable<UserData, any>
                      columns={userColumns}
                      dense={true}
                      loadMore={async () => {
                        if (!userListLoading.current) {
                          userListLoading.current = true;
                          usersRequest.run({
                            variables: { first: 50, after: userListData.endCursor },
                          });
                        }
                      }}
                      hasMore={userListData.hasNextPage}
                      data={userListData.users}
                      getKey={getKey}
                      rowLink={rowLink}
                    />
                  </div>
                </ListContainer>
              </Grid>
            </Grid>
          </Content>
        </Scrollable>
      </Layout>
    );
  }

  return null;
}
