import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, LinkProps } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  anchor: {
    ':visited': {
      color: theme.palette.primary.main,
    },
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}))

const PrimaryLink: React.FunctionComponent<LinkProps> = (props) => {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <Link className={[classes.anchor, props.className].join(' ')} {...rest}>{children}</Link>
  );
};

export default PrimaryLink;
