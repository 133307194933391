import React, {useState} from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import SimpleMenu from "../../components/SimpleMenu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MenuItem from "@material-ui/core/MenuItem";
import raw from "raw.macro";
import {getApiToken} from "../../utils/getApiToken";
import {apiRequest} from "../../utils/apiRequest";
import {ClearCacheMutation, ClearCacheMutationVariables} from "../../apiTypes";
import {useAsyncTasks} from "../asyncTasks/store";
import {useAuth0} from "@auth0/auth0-react";

const clearCacheQuery = raw('../../queries/clearCache.graphql');

export default () => {
  const { asyncTaskActions } = useAsyncTasks();
  const { getAccessTokenSilently } = useAuth0();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClearPlansCache = () => {
    setMenuOpen(false);
    asyncTaskActions.addTask(`Clear plans cache`, async () => {
      const accessToken = await getApiToken(getAccessTokenSilently);
      await apiRequest<ClearCacheMutation, ClearCacheMutationVariables>({
        accessToken,
        query: clearCacheQuery,
        variables: { input: { plan: 'all' } }
      });
    });
  }

  return (
    <Toolbar style={{ borderBottom: '1px solid lightgrey' }}>
      <div style={{ flexGrow: 1 }} />
      <SimpleMenu
        open={menuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        buttonContent={<>Actions<ArrowDropDownIcon fontSize="small" /></>}
        buttonProps={{
          color: 'primary',
          variant: 'outlined',
          size: 'small',
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem dense onClick={handleClearPlansCache}>
          Clear plans cache
        </MenuItem>
      </SimpleMenu>
    </Toolbar>
  );
};
