import React from 'react';
import Routes from './Routes';
import AsyncTaskList from './features/asyncTasks/AsyncTaskList';
import LoginIfNeeded from './features/auth/LoginIfNeeded';

export default () => (
  <LoginIfNeeded>
    <Routes />
    <AsyncTaskList />
  </LoginIfNeeded>
);
