import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import PlainLink from './PlainLink';
import { LOGOUT } from '../Routes';

export type MainAppBarProps = {
  title: string,
  onMenuClick: () => void,
};

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
}));

export default ({ title, onMenuClick }: MainAppBarProps) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const classes = useStyles();
  const { user } = useAuth0();
  const open = !!anchorEl;

  const handleMenu = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onMenuClick}
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleMenu}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
            disableAutoFocusItem={true}
          >
            <MenuItem button={false}>{user.email}</MenuItem>
            <PlainLink to={LOGOUT} ><MenuItem>Logout</MenuItem></PlainLink>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};
