import React, { useState } from 'react';
import { Maybe } from '../../../types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { Centered } from '../../../utils/ui';
import { useMutation } from '../../../hooks/useMutation';
import {
  PlansQuery,
  PlansQueryVariables,
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables,
} from '../../../apiTypes';
import raw from 'raw.macro';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useQuery } from '../../../hooks/useQuery';
import TextField from '@material-ui/core/TextField';
import { getPlanLabel } from '../../../utils/plans';

const updateSubscriptionQuery = raw('../../../queries/updateSubscription.graphql');
const plansQuery = raw('../../../queries/plans.graphql');

export type UpdateSubscriptionDialogPresentationProps = {
  loading: boolean,
  error: Maybe<string>,
  onSubmit: (data: { endTrial: boolean, planId: string, couponCode: string }) => void,
  onClose: () => void,
  open: boolean,
  plans: Maybe<PlansQuery['plans']>,
};

export type UpdateSubscriptionDialogProps = {
  tenantId: string,
  onSuccess: () => void,
  onClose: () => void,
  open: boolean,
};

const UpdateSubscriptionDialogPresentation = (props: UpdateSubscriptionDialogPresentationProps) => {
  const [endTrial, setEndTrial] = useState(false);
  const [planId, setPlanId] = useState('');
  const [couponCode, setCouponCode] = useState('');

  const handleEndTrialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndTrial(event.target.checked);
  }

  const canSubmit = !props.loading && planId;

  const options: Array<{ label: string, value: string }> = props.plans
    ? props.plans.map((plan) => ({
      label: getPlanLabel(plan),
      value: plan.id,
    }))
    : [];

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Update Subscription</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        {!!props.error && <DialogContentText color="error">{props.error}</DialogContentText>}
        {props.loading && (
          <Centered>
            <CircularProgress />
          </Centered>
        )}
        {!props.loading && (
          <>
            <FormGroup row style={{ marginBottom: 24 }}>
              <Autocomplete
                style={{ width: '100%' }}
                options={options}
                renderInput={(params) => (
                  <TextField {...params} label="Plan ID" />
                )}
                freeSolo
                multiple={false}
                disableClearable={false}
                onInputChange={(event, value) => setPlanId(value)}
                renderOption={(option) => option.label}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.value || '';
                }}
              />
            </FormGroup>
            <FormGroup row>
              <TextField
                label="Coupon Code"
                onChange={(event) => setCouponCode(event.target.value)}
                style={{ marginRight: 8 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={endTrial}
                    onChange={handleEndTrialChange}
                    name="endTrial"
                    color="primary"
                  />
                }
                label="End Trial"
                style={{ paddingTop: 16 }}
              />
            </FormGroup>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button style={{ color: 'gray' }} onClick={props.onClose}>Cancel</Button>
        <Button
          disabled={!canSubmit}
          color={canSubmit ? 'primary' : 'default'}
          onClick={() => canSubmit && props.onSubmit({ endTrial, planId, couponCode })}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default (props: UpdateSubscriptionDialogProps) => {
  const [updateSubscription, { loading, error }] = useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>({
    query: updateSubscriptionQuery,
    tenantId: props.tenantId,
    onCompleted: props.onSuccess,
  });

  const plansRequest = useQuery<PlansQuery, PlansQueryVariables>({
    query: plansQuery,
  });

  return (
    <UpdateSubscriptionDialogPresentation
      open={props.open}
      loading={loading || plansRequest.loading}
      error={error && 'Error'}
      plans={plansRequest.data && plansRequest.data.plans}
      onSubmit={(data) => {
        updateSubscription({
          variables: {
            input: {
              couponCode: data.couponCode,
              endFreeTrial: data.endTrial,
              planId: data.planId,
            },
          },
        })
      }}
      onClose={props.onClose}
    />
  );
};
