export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** 64 bit signed integer, input/output as string */
  BigInt: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** IS0-8601 string */
  Date: any;
  /** String, ISO-8601 date formatted string, or boolean */
  FieldValue: any;
};

export type File = Node & {
  __typename?: 'File';
  id: Scalars['ID'];
  /** Identifies the primary key from the database */
  databaseId: Scalars['String'];
  /** Size of the file, in bytes */
  size: Scalars['BigInt'];
  /** Original name of the file, read-only */
  name: Scalars['String'];
  /** Modifiable title of the file */
  title: Scalars['String'];
  description: Scalars['String'];
  fileType: FileType;
  originalUrl?: Maybe<Scalars['String']>;
  originalPath?: Maybe<Scalars['String']>;
  mimeType: Scalars['String'];
  isFolder: Scalars['Boolean'];
  hashes: Scalars['JSON'];
  expiresOn?: Maybe<Scalars['Date']>;
  createdOn: Scalars['Date'];
  modifiedOn: Scalars['Date'];
  deletedOn?: Maybe<Scalars['Date']>;
  originalCreatedOn?: Maybe<Scalars['Date']>;
  isNft: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  meta: FileMeta;
  currentUrl?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  canvaUrl?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<FileThumbnail>;
  /** @deprecated Moved into `thumbnail` */
  thumbnailUrl?: Maybe<Scalars['String']>;
  preview?: Maybe<FilePreview>;
  derivatives: Array<FileDerivative>;
  tags: Array<Scalars['String']>;
  owner: User;
  createdBy: User;
  modifiedBy: User;
  deletedBy?: Maybe<User>;
  mintedBy?: Maybe<User>;
  allowedUsers: FileUserConnection;
  extension?: Maybe<Scalars['String']>;
  currentPath: Scalars['String'];
  parent?: Maybe<File>;
  parents: FileConnection;
  children: FileConnection;
  collections: CollectionConnection;
  permissions: Array<FilePermission>;
  actions?: Maybe<FileActions>;
  nftInfo?: Maybe<NftInfo>;
  nftFieldEntries: Array<FieldEntry>;
  fieldEntries: Array<FieldEntry>;
};


export type FileChildrenArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FileOrderField>;
};


export type FileFieldEntriesArgs = {
  fieldIds?: Maybe<Array<Scalars['ID']>>;
};

export type Node = {
  id: Scalars['ID'];
};


export enum FileType {
  Image = 'IMAGE',
  Audio = 'AUDIO',
  Video = 'VIDEO',
  Document = 'DOCUMENT',
  Text = 'TEXT',
  Other = 'OTHER',
  Folder = 'FOLDER'
}



export type FileMeta = {
  __typename?: 'FileMeta';
  core?: Maybe<Scalars['JSON']>;
  extracted?: Maybe<Scalars['JSON']>;
  detected?: Maybe<DetectedFileMeta>;
};

export type DetectedFileMeta = {
  __typename?: 'DetectedFileMeta';
  labels: Array<Scalars['String']>;
};

export type FileThumbnail = {
  __typename?: 'FileThumbnail';
  url: Scalars['String'];
  derivativeInfo?: Maybe<DerivativeInfo>;
};

export type DerivativeInfo = {
  __typename?: 'DerivativeInfo';
  width: Scalars['Int'];
  height: Scalars['Int'];
  blurHash: Scalars['String'];
};

export type FilePreview = {
  __typename?: 'FilePreview';
  url: Scalars['String'];
  mimeType: Scalars['String'];
  fileType: FileType;
  derivativeInfo?: Maybe<DerivativeInfo>;
};

export type FileDerivative = {
  __typename?: 'FileDerivative';
  name: Scalars['String'];
  size: Scalars['Int'];
  derivativeType?: Maybe<FileDerivativeType>;
  url: Scalars['String'];
  downloadUrl: Scalars['String'];
  mimeType: Scalars['String'];
};

export enum FileDerivativeType {
  Thumbnail = 'THUMBNAIL',
  Preview = 'PREVIEW'
}

export type User = Node & {
  __typename?: 'User';
  id: Scalars['ID'];
  /** Identifies the primary key from the database */
  databaseId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  fullName: Scalars['String'];
  invitedOn?: Maybe<Scalars['Date']>;
  joinedOn?: Maybe<Scalars['Date']>;
  status: UserStatus;
  billingType: UserBillingType;
  isOwner: Scalars['Boolean'];
  canReactivateForFree: Scalars['Boolean'];
  /** @deprecated Replaced by permissions */
  role: UserRole;
  permissions: Array<UserPermission>;
  authId?: Maybe<Scalars['String']>;
  acceptedTerms?: Maybe<Scalars['Boolean']>;
  acceptedTermsOn?: Maybe<Scalars['Date']>;
  createdOn: Scalars['Date'];
  modifiedOn: Scalars['Date'];
  createdBy: User;
  modifiedBy: User;
  isViewer: Scalars['Boolean'];
  /** Only accessible if this user is the viewer */
  canRequestEmailChange?: Maybe<Scalars['Boolean']>;
  /** Only accessible if this user is the viewer */
  emailVerified?: Maybe<Scalars['Boolean']>;
  tenant: Tenant;
  mfaEnabled: Scalars['Boolean'];
};

export enum UserStatus {
  Invited = 'INVITED',
  InviteExpired = 'INVITE_EXPIRED',
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Deleted = 'DELETED',
  Suspended = 'SUSPENDED',
  Blocked = 'BLOCKED',
  Unknown = 'UNKNOWN'
}

export enum UserBillingType {
  Seat = 'SEAT',
  Paid = 'PAID'
}

export enum UserRole {
  /** The creator of the Starchive. The owner is the only member of a Starchive who can delete a Starchive or change the plan or payment method. A Starchive can have only one owner. */
  Owner = 'OWNER',
  /** An administrator of a Starchive. The admin has some additional administrative permissions is Starchive. They can manage members and their status and rename a Starchive or disable downloading. A Starchive can have more than one Admin. */
  Admin = 'ADMIN',
  /** An active member of a Starchive who can manage and work with content and new invite members, but has no administrative or ownership role. A Starchive can have many producers. */
  Producer = 'PRODUCER',
  /** A authenticated member of a Starchive who can view content, but cannot manage and work with content or invite other members. */
  Guest = 'GUEST'
}

export enum UserPermission {
  TotalAccess = 'TOTAL_ACCESS',
  ManageMembers = 'MANAGE_MEMBERS',
  LimitedAccess = 'LIMITED_ACCESS',
  Upload = 'UPLOAD',
  SeeOthersFiles = 'SEE_OTHERS_FILES',
  EditOthersFiles = 'EDIT_OTHERS_FILES',
  Share = 'SHARE',
  Owner = 'OWNER'
}

export type Tenant = Node & {
  __typename?: 'Tenant';
  meta: Scalars['JSON'];
  stripeCustomer?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Identifies the primary key from the database */
  databaseId: Scalars['String'];
  name: Scalars['String'];
  createdOn: Scalars['Date'];
  /** @deprecated Moved to subscription.plan */
  userCapacity: Scalars['Int'];
  status: TenantStatus;
  subscription: Subscription;
  /** This is the coupon code entered when signing up. This does NOT mean this coupon is active. See Subscription.discount for that. */
  coupon?: Maybe<Coupon>;
  owner: User;
  capacities: TenantCapacities;
  fileSizeCapacity?: Maybe<Scalars['BigInt']>;
  totalFileSize: Scalars['BigInt'];
  totalFileCount: Scalars['Int'];
  /** Returns the number of users counting towards user capacity */
  totalUsers: Scalars['Int'];
  remainingUserCapacity: Scalars['Int'];
  canUpgradeUserCapacity: Scalars['Boolean'];
  info: TenantInfo;
};

export enum TenantStatus {
  Disabled = 'DISABLED',
  Active = 'ACTIVE',
  FreeTrialExpired = 'FREE_TRIAL_EXPIRED',
  PaymentFailed = 'PAYMENT_FAILED'
}

export type Subscription = {
  __typename?: 'Subscription';
  isFreeTrial: Scalars['Boolean'];
  nextCharge?: Maybe<Scalars['Date']>;
  /** Note: this invoice object cannot be refetched by id */
  nextInvoice?: Maybe<Invoice>;
  periodEnd: Scalars['Date'];
  discount?: Maybe<Discount>;
  addOns: Array<SubscribedAddOn>;
  flags: Array<SubscriptionFlags>;
  plan: Plan;
};

export type Invoice = Node & {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  title: Scalars['String'];
  periodStart: FormattedDate;
  periodEnd: FormattedDate;
  paidOn?: Maybe<FormattedDate>;
  /** @deprecated No longer accurate */
  subscriptionAmount?: Maybe<Currency>;
  /** @deprecated No longer accurate */
  storageAmount?: Maybe<Currency>;
  /** @deprecated Moved to amountPaid */
  totalAmount: Currency;
  amountPaid: Currency;
  amountDue: Currency;
  amountRemaining: Currency;
  lines: Array<InvoiceLineItem>;
  /** URL to download pdf for this invoice. Will be null if not finalized. */
  pdfUrl?: Maybe<Scalars['String']>;
};

/** Allows retrieving a date in various formats */
export type FormattedDate = {
  __typename?: 'FormattedDate';
  iso: Scalars['Date'];
  relative: Scalars['String'];
  format: Scalars['String'];
};


/** Allows retrieving a date in various formats */
export type FormattedDateFormatArgs = {
  format: Scalars['String'];
};

export type Currency = {
  __typename?: 'Currency';
  /** Formatted value including currency symbol. Optionally returns "Free" for 0 */
  formatted: Scalars['String'];
  /** Currency value in US cents */
  cents: Scalars['Int'];
};


export type CurrencyFormattedArgs = {
  showFree?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  description: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  amount: Currency;
  discounts?: Maybe<Array<InvoiceLineItemDiscount>>;
  isProration: Scalars['Boolean'];
};

export type InvoiceLineItemDiscount = {
  __typename?: 'InvoiceLineItemDiscount';
  amount: Currency;
  discount: Discount;
};

export type Discount = {
  __typename?: 'Discount';
  startedOn: Scalars['Date'];
  endsOn?: Maybe<Scalars['Date']>;
  coupon?: Maybe<Coupon>;
};

export type Coupon = Node & {
  __typename?: 'Coupon';
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  duration: CouponDuration;
  durationInMonths?: Maybe<Scalars['Int']>;
  percentOff?: Maybe<Scalars['Float']>;
  amountOff?: Maybe<Currency>;
  description: Scalars['String'];
  planValidity: CouponPlanValidity;
  validPlans?: Maybe<Array<Plan>>;
  /** Returns true if coupon is for 100% off of both storage and tier fees */
  isFree: Scalars['Boolean'];
};

export enum CouponDuration {
  Forever = 'FOREVER',
  Once = 'ONCE',
  Repeating = 'REPEATING'
}

export enum CouponPlanValidity {
  All = 'ALL',
  Specific = 'SPECIFIC'
}

export type Plan = Node & {
  __typename?: 'Plan';
  tenants: TenantConnection;
  isActive: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Identifies the primary key from the database */
  databaseId: Scalars['String'];
  /** @deprecated Moved to Subscription */
  isFreeTrial: Scalars['Boolean'];
  stripeId: Scalars['String'];
  stripeName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Price per billing interval */
  price: Currency;
  interval: BillingInterval;
  userCapacityType: UserCapacityType;
  userCapacity: Scalars['Int'];
  storageCapacity?: Maybe<Scalars['BigInt']>;
  /** If authenticated, returns whether or not switching to this plan requires contacting us. */
  requiresContact?: Maybe<Scalars['Boolean']>;
};


export type PlanTenantsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  meta?: Maybe<TenantMetaFilter>;
  active?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<TenantOrder>;
  email?: Maybe<TenantEmailFilter>;
};


export type PlanPriceArgs = {
  multipliedBy?: Maybe<Scalars['Float']>;
  dividedBy?: Maybe<Scalars['Float']>;
};

export type TenantConnection = {
  __typename?: 'TenantConnection';
  /** A list of nodes. */
  nodes: Array<Tenant>;
  /** A list of edges. */
  edges: Array<TenantEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TenantEdge = {
  __typename?: 'TenantEdge';
  node: Tenant;
  cursor: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasPreviousPage: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type TenantMetaFilter = {
  status?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  coupon?: Maybe<Scalars['String']>;
};

export type TenantOrder = {
  direction: Order;
  field: TenantOrderField;
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum TenantOrderField {
  Id = 'ID',
  Name = 'NAME',
  CreatedOn = 'CREATED_ON'
}

export type TenantEmailFilter = {
  operator: TenantEmailOperator;
  values: Array<Scalars['String']>;
};

export enum TenantEmailOperator {
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL'
}

export enum BillingInterval {
  Month = 'MONTH',
  Year = 'YEAR'
}

export enum UserCapacityType {
  Individual = 'INDIVIDUAL',
  Family = 'FAMILY',
  Team = 'TEAM'
}

export type SubscribedAddOn = {
  __typename?: 'SubscribedAddOn';
  addOn: AddOn;
  billableUnits: Scalars['Int'];
  discount?: Maybe<Discount>;
  subtotal: Currency;
  total: Currency;
};

export type AddOn = Node & {
  __typename?: 'AddOn';
  id: Scalars['ID'];
  /** Identifies the primary key from the database */
  databaseId: Scalars['String'];
  interval: BillingInterval;
  type: AddOnType;
  unitPrice: Currency;
  unitQuantity: Scalars['Int'];
  freeUnits: Scalars['Int'];
  maxUnits?: Maybe<Scalars['Int']>;
};

export enum AddOnType {
  Plan = 'PLAN',
  Storage = 'STORAGE',
  Member = 'MEMBER'
}

export enum SubscriptionFlags {
  Free = 'FREE',
  Paid = 'PAID',
  Lifetime = 'LIFETIME'
}

export type TenantCapacities = {
  __typename?: 'TenantCapacities';
  seats: Scalars['Int'];
  seatsFilled: Scalars['Int'];
  seatsAvailable: Scalars['Int'];
  /** Number of users over the seat limit */
  paidUsers: Scalars['Int'];
  includedStorage: StorageSize;
  remainingIncludedStorage: StorageSize;
  remainingIncludedStoragePercent: Scalars['Float'];
  consumedStorage: StorageSize;
  maxStorage?: Maybe<StorageSize>;
  /** Amount of storage consumed over the included storage */
  consumedAdditionalStorage: StorageSize;
};

export type StorageSize = {
  __typename?: 'StorageSize';
  /** Formatted value including unit */
  formatted: Scalars['String'];
  /** Size in bytes */
  bytes: Scalars['BigInt'];
};

export type TenantInfo = {
  __typename?: 'TenantInfo';
  general: TenantInfoGeneral;
  billing?: Maybe<TenantInfoBilling>;
};

export type TenantInfoGeneral = {
  __typename?: 'TenantInfoGeneral';
  phoneNumber?: Maybe<Scalars['String']>;
  /** @deprecated Moved to billing */
  addressLine1?: Maybe<Scalars['String']>;
  /** @deprecated Moved to billing */
  addressLine2?: Maybe<Scalars['String']>;
  /** @deprecated Moved to billing */
  city?: Maybe<Scalars['String']>;
  /** @deprecated Moved to billing */
  state?: Maybe<Scalars['String']>;
  /** @deprecated Moved to billing */
  zip?: Maybe<Scalars['String']>;
  /** @deprecated Moved to billing */
  country?: Maybe<Scalars['String']>;
};

export type TenantInfoBilling = {
  __typename?: 'TenantInfoBilling';
  /** Cardholder name */
  name?: Maybe<Scalars['String']>;
  /** Possible values: Visa, American Express, MasterCard, Discover, JCB, Diners Club, Unknown */
  cardType: Scalars['String'];
  lastFour: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type FileUserConnection = {
  __typename?: 'FileUserConnection';
  /** A list of nodes. */
  nodes: Array<User>;
  /** A list of edges. */
  edges: Array<FileUserEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FileUserEdge = {
  __typename?: 'FileUserEdge';
  node: User;
  cursor: Scalars['String'];
  permissions: Array<FilePermission>;
  addedOn: Scalars['Date'];
  addedBy: User;
};

export enum FilePermission {
  TotalAccess = 'TOTAL_ACCESS',
  Upload = 'UPLOAD',
  LimitedAccess = 'LIMITED_ACCESS',
  SeeOthersFiles = 'SEE_OTHERS_FILES',
  EditOthersFiles = 'EDIT_OTHERS_FILES',
  Share = 'SHARE',
  Owner = 'OWNER'
}

export type FileConnection = {
  __typename?: 'FileConnection';
  /** A list of nodes. */
  nodes: Array<File>;
  /** A list of edges. */
  edges: Array<FileEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  batchKey: Scalars['String'];
  aggregations: FileAggregations;
  topHits: TopHits;
};

export type FileEdge = {
  __typename?: 'FileEdge';
  node: File;
  cursor: Scalars['String'];
};

export type FileAggregations = {
  __typename?: 'FileAggregations';
  count: Scalars['Int'];
  countIsEstimate: Scalars['Boolean'];
  size: Scalars['BigInt'];
  sizeIsEstimate: Scalars['Boolean'];
  lastModified?: Maybe<Scalars['Date']>;
  lastCreated?: Maybe<Scalars['Date']>;
};

export type TopHits = {
  __typename?: 'TopHits';
  keywords: Array<KeywordHit>;
  tags: Array<TagHit>;
  fileTypes: Array<FileTypeHit>;
};

export type KeywordHit = {
  __typename?: 'KeywordHit';
  hitCount: Scalars['Int'];
  value: Scalars['String'];
};

export type TagHit = {
  __typename?: 'TagHit';
  hitCount: Scalars['Int'];
  value: Scalars['String'];
};

export type FileTypeHit = {
  __typename?: 'FileTypeHit';
  hitCount: Scalars['Int'];
  value: FileType;
};

export enum FileOrderField {
  Name = 'NAME',
  Title = 'TITLE',
  Size = 'SIZE',
  CreatedOn = 'CREATED_ON',
  ModifiedOn = 'MODIFIED_ON',
  OriginalCreatedOn = 'ORIGINAL_CREATED_ON'
}

export type CollectionConnection = {
  __typename?: 'CollectionConnection';
  /** A list of nodes. */
  nodes: Array<Collection>;
  /** A list of edges. */
  edges: Array<CollectionEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  aggregations?: Maybe<FileAggregations>;
};

export type Collection = Node & {
  __typename?: 'Collection';
  id: Scalars['ID'];
  /** Identifies the primary key from the database */
  databaseId: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isPublic: Scalars['Boolean'];
  createdOn: Scalars['Date'];
  modifiedOn: Scalars['Date'];
  createdBy: User;
  modifiedBy: User;
  owner: User;
  meta?: Maybe<CollectionMeta>;
  files: CollectionFileConnection;
  allowedUsers: CollectionUserConnection;
  /** The permissions the viewer has for this Collection */
  permissions: Array<CollectionPermission>;
};


export type CollectionFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<CollectionFileOrderField>;
};

export type CollectionMeta = {
  __typename?: 'CollectionMeta';
  tags: Array<Scalars['String']>;
};

export type CollectionFileConnection = {
  __typename?: 'CollectionFileConnection';
  /** A list of nodes. */
  nodes: Array<File>;
  /** A list of edges. */
  edges: Array<CollectionFileEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  batchKey: Scalars['String'];
  aggregations: FileAggregations;
  topHits: TopHits;
};

export type CollectionFileEdge = {
  __typename?: 'CollectionFileEdge';
  node: File;
  cursor: Scalars['String'];
  collection: Collection;
  title?: Maybe<Scalars['String']>;
  createdOn: Scalars['Date'];
  modifiedOn: Scalars['Date'];
  createdBy: User;
  modifiedBy: User;
};

export enum CollectionFileOrderField {
  Position = 'POSITION',
  AddedOn = 'ADDED_ON',
  ModifiedOn = 'MODIFIED_ON',
  Title = 'TITLE',
  Size = 'SIZE'
}

export type CollectionUserConnection = {
  __typename?: 'CollectionUserConnection';
  /** A list of nodes. */
  nodes: Array<User>;
  /** A list of edges. */
  edges: Array<CollectionUserEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CollectionUserEdge = {
  __typename?: 'CollectionUserEdge';
  node: User;
  cursor: Scalars['String'];
  permissions: Array<CollectionPermission>;
  addedOn: Scalars['Date'];
  addedBy: User;
};

export enum CollectionPermission {
  TotalAccess = 'TOTAL_ACCESS',
  LimitedAccess = 'LIMITED_ACCESS',
  Add = 'ADD',
  SeeOthersFiles = 'SEE_OTHERS_FILES',
  EditOthersFiles = 'EDIT_OTHERS_FILES',
  Share = 'SHARE',
  Owner = 'OWNER'
}

export type CollectionEdge = {
  __typename?: 'CollectionEdge';
  node: Collection;
  cursor: Scalars['String'];
};

export type FileActions = {
  __typename?: 'FileActions';
  canDelete: Scalars['Boolean'];
  mint: MintAction;
};

export type MintAction = {
  __typename?: 'MintAction';
  canMint: Scalars['Boolean'];
  flags: Array<MintActionFlagInfo>;
};

export type MintActionFlagInfo = {
  __typename?: 'MintActionFlagInfo';
  flag: MintActionFlags;
  message: Scalars['String'];
};

export enum MintActionFlags {
  /** File type may not be supported on all platforms */
  FileTypeWarning = 'FILE_TYPE_WARNING',
  /** File too large to mint, maximum size is 50 MB */
  FileTooLarge = 'FILE_TOO_LARGE',
  /** File has already been minted */
  FileAlreadyMinted = 'FILE_ALREADY_MINTED'
}

export type NftInfo = {
  __typename?: 'NftInfo';
  chain?: Maybe<WalletChain>;
  chainId: Scalars['BigInt'];
  mintedBy?: Maybe<Web3Identity>;
  owner?: Maybe<Web3Identity>;
  contractAddress?: Maybe<Web3ExplorerValue>;
  tokenId?: Maybe<Web3ExplorerValue>;
  listings: Array<NftListing>;
};

export enum WalletChain {
  Ethereum = 'ETHEREUM',
  Rinkeby = 'RINKEBY',
  Palm = 'PALM',
  PalmTestnet = 'PALM_TESTNET',
  Polygon = 'POLYGON',
  PolygonMumbai = 'POLYGON_MUMBAI'
}

export type Web3Identity = {
  __typename?: 'Web3Identity';
  user?: Maybe<User>;
  address?: Maybe<Web3ExplorerValue>;
};

export type Web3ExplorerValue = {
  __typename?: 'Web3ExplorerValue';
  value: Scalars['String'];
  link: Scalars['String'];
};

export type NftListing = Node & {
  __typename?: 'NftListing';
  id: Scalars['ID'];
  /** Identifies the primary key from the database */
  databaseId: Scalars['String'];
  checkoutUrl: Scalars['String'];
  key: Scalars['String'];
  asset: Nft;
  tokenId: Scalars['String'];
  contractAddress: Scalars['String'];
  createdOn: Scalars['Date'];
  modifiedOn: Scalars['Date'];
  seller: NftListingSeller;
  priceUsd: Currency;
  createdBy: User;
  modifiedBy: User;
};

export type Nft = {
  __typename?: 'Nft';
  contract: Web3AssetContract;
  tokenId: Scalars['String'];
  owner: Web3AssetOwner;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  cachedMedia?: Maybe<NftCachedMedia>;
  attributes: NftAttributes;
};

export type Web3AssetContract = {
  __typename?: 'Web3AssetContract';
  chainId: Scalars['BigInt'];
  chain: WalletChain;
  address: Scalars['String'];
};

export type Web3AssetOwner = {
  __typename?: 'Web3AssetOwner';
  address: Scalars['String'];
};

export type NftCachedMedia = {
  __typename?: 'NftCachedMedia';
  thumbnail?: Maybe<FileThumbnail>;
  preview?: Maybe<FilePreview>;
};

export type NftAttributes = {
  __typename?: 'NftAttributes';
  raw: Array<Scalars['JSON']>;
  properties: Array<NftProperty>;
  levels: Array<NftLevel>;
  dates: Array<NftDate>;
  other: Array<NftOtherAttribute>;
};

export type NftProperty = {
  __typename?: 'NftProperty';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type NftLevel = {
  __typename?: 'NftLevel';
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type NftDate = {
  __typename?: 'NftDate';
  name: Scalars['String'];
  value: Scalars['Date'];
};

export type NftOtherAttribute = {
  __typename?: 'NftOtherAttribute';
  name: Scalars['String'];
  value: Scalars['JSON'];
};

export type NftListingSeller = {
  __typename?: 'NftListingSeller';
  name: Scalars['String'];
  verified: Scalars['Boolean'];
};

export type FieldEntry = {
  file: File;
  field: Field;
  /** Returns field values as a list regardless of type */
  valueList: Array<FieldValueRecord>;
};

export type Field = Node & {
  __typename?: 'Field';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
  valueCount: Scalars['Int'];
  values: FieldValueConnection;
  fileCount: Scalars['Int'];
  files: FileConnection;
  permissions: FieldPermissions;
  isGlobal: Scalars['Boolean'];
  createdOn: Scalars['Date'];
  modifiedOn: Scalars['Date'];
  createdBy: User;
  modifiedBy: User;
};


export type FieldValuesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FieldValueOrderField>;
  query?: Maybe<Scalars['String']>;
};


export type FieldFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FileSearchOrderField>;
};

export enum FieldType {
  Boolean = 'BOOLEAN',
  Paragraph = 'PARAGRAPH',
  Date = 'DATE',
  List = 'LIST'
}

export type FieldValueConnection = {
  __typename?: 'FieldValueConnection';
  /** A list of nodes. */
  nodes: Array<FieldValueRecord>;
  /** A list of edges. */
  edges: Array<FieldValueEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FieldValueRecord = {
  id: Scalars['ID'];
  field: Field;
  fileCount: Scalars['Int'];
  files: FileConnection;
  value: Scalars['FieldValue'];
};


export type FieldValueRecordFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FileSearchOrderField>;
};

export enum FileSearchOrderField {
  Name = 'NAME',
  Title = 'TITLE',
  Size = 'SIZE',
  CreatedOn = 'CREATED_ON',
  ModifiedOn = 'MODIFIED_ON',
  OriginalCreatedOn = 'ORIGINAL_CREATED_ON',
  /** Only valid when `query` arg has a value */
  Rank = 'RANK'
}


export type FieldValueEdge = {
  __typename?: 'FieldValueEdge';
  node: FieldValueRecord;
  cursor: Scalars['String'];
};

export enum FieldValueOrderField {
  Value = 'VALUE',
  Count = 'COUNT'
}

export type FieldPermissions = {
  __typename?: 'FieldPermissions';
  isPublic: Scalars['Boolean'];
  externalAccess: Scalars['Boolean'];
};

export type BooleanFieldValueRecord = FieldValueRecord & Node & {
  __typename?: 'BooleanFieldValueRecord';
  id: Scalars['ID'];
  field: Field;
  fileCount: Scalars['Int'];
  files: FileConnection;
  value: Scalars['FieldValue'];
  booleanValue: Scalars['Boolean'];
};


export type BooleanFieldValueRecordFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FileSearchOrderField>;
};

export type ParagraphFieldValueRecord = FieldValueRecord & Node & {
  __typename?: 'ParagraphFieldValueRecord';
  id: Scalars['ID'];
  field: Field;
  fileCount: Scalars['Int'];
  files: FileConnection;
  value: Scalars['FieldValue'];
  stringValue: Scalars['String'];
};


export type ParagraphFieldValueRecordFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FileSearchOrderField>;
};

export type DateFieldValueRecord = FieldValueRecord & Node & {
  __typename?: 'DateFieldValueRecord';
  id: Scalars['ID'];
  field: Field;
  fileCount: Scalars['Int'];
  files: FileConnection;
  value: Scalars['FieldValue'];
  dateValue: Scalars['Date'];
};


export type DateFieldValueRecordFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FileSearchOrderField>;
};

export type ListFieldValueRecord = FieldValueRecord & Node & {
  __typename?: 'ListFieldValueRecord';
  id: Scalars['ID'];
  field: Field;
  fileCount: Scalars['Int'];
  files: FileConnection;
  value: Scalars['FieldValue'];
  stringValue: Scalars['String'];
};


export type ListFieldValueRecordFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FileSearchOrderField>;
};

export type SingleValueFieldEntry = FieldEntry & {
  __typename?: 'SingleValueFieldEntry';
  file: File;
  field: Field;
  /** Returns field values as a list regardless of type */
  valueList: Array<FieldValueRecord>;
  value?: Maybe<FieldValueRecord>;
};

export type ListFieldEntry = FieldEntry & {
  __typename?: 'ListFieldEntry';
  file: File;
  field: Field;
  /** Returns field values as a list regardless of type */
  valueList: Array<FieldValueRecord>;
  values: Array<FieldValueRecord>;
};

/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type Query = {
  __typename?: 'Query';
  accessTokens: AccessTokenConnection;
  activeUsers: Array<User>;
  /** *Does not require access token or x-tenant-id header* */
  addOns: Array<AddOn>;
  /** *Requires admin token* */
  allPlans: Array<Plan>;
  collections: CollectionConnection;
  /** *Does not require access token or x-tenant-id header* */
  coupon: Coupon;
  field?: Maybe<Field>;
  fieldValueRecord: FieldValueRecord;
  fieldValues: FieldValueConnection;
  fields: FieldConnection;
  /** Fetch a file by path or upload ID. If both args are specified, `uploadId` has priority */
  file?: Maybe<File>;
  fileActivity: Array<FileActivity>;
  /** Fetch files by path or upload ID. If both args are specified, `uploadIds` has priority. Files are returned matching the order of the args. Note that items can be null. */
  files: Array<Maybe<File>>;
  filesystem: FileConnection;
  /** *Does not require access token* */
  getCreateZipJobs: Array<CreateZipBackgroundJob>;
  invoices: InvoiceConnection;
  listNftSignatureInfo?: Maybe<ListNftSignatureInfo>;
  /** *Does not require x-tenant-id header* */
  memberships: Array<Membership>;
  /** *Does not require access token or x-tenant-id header* */
  nftListing?: Maybe<NftListing>;
  node: Node;
  nodes: Array<Node>;
  /** *Does not require access token or x-tenant-id header* */
  plans: Array<Plan>;
  previewInvoice: Array<Invoice>;
  /** *Does not require access token* */
  publicFile?: Maybe<File>;
  /** *Does not require access token or x-tenant-id header* */
  publicUpload?: Maybe<PublicUpload>;
  publicUploads: PublicUploadConnection;
  /**
   * True if the currently authenticated identity requires email verification
   * 
   * *Does not require x-tenant-id header*
   */
  requiresEmailVerification?: Maybe<Scalars['Boolean']>;
  search: FileConnection;
  searchSuggestions: SearchSuggestions;
  /** *Does not require access token* */
  share: Share;
  shares: ShareConnection;
  storageHistory: StorageUsageConnection;
  storageUsage: StorageUsage;
  tags: TagConnection;
  tenant: Tenant;
  /** *Requires admin token* */
  tenants: TenantConnection;
  /** *Does not require access token or x-tenant-id header* */
  tiers: Array<Tier>;
  user?: Maybe<User>;
  users: UserConnection;
  /** *Does not require access token or x-tenant-id header* */
  version: Scalars['String'];
  viewer: User;
  wallet?: Maybe<WalletGql>;
  wallets: Array<WalletGql>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryCollectionsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  sort?: Maybe<CollectionOrderField>;
  order?: Maybe<Order>;
  query?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  ownerEmails?: Maybe<Array<Scalars['String']>>;
  ownerIds?: Maybe<Array<Scalars['ID']>>;
  canAdd?: Maybe<Scalars['Boolean']>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryCouponArgs = {
  code: Scalars['String'];
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryFieldArgs = {
  name: Scalars['String'];
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryFieldValueRecordArgs = {
  id: Scalars['ID'];
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryFieldValuesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FieldValueOrderField>;
  query?: Maybe<Scalars['String']>;
  fieldId: Scalars['ID'];
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryFieldsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FieldOrderField>;
  query?: Maybe<Scalars['String']>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryFileArgs = {
  path?: Maybe<Scalars['String']>;
  relativeTo?: Maybe<Scalars['ID']>;
  uploadId?: Maybe<Scalars['String']>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryFileActivityArgs = {
  field: FileDateField;
  limit?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Date']>;
  after?: Maybe<Scalars['Date']>;
  folderInclusion?: Maybe<Inclusion>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryFilesArgs = {
  paths?: Maybe<Array<Scalars['String']>>;
  uploadIds?: Maybe<Array<Scalars['String']>>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryFilesystemArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FileOrderField>;
  folderId?: Maybe<Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryGetCreateZipJobsArgs = {
  ids: Array<Scalars['ID']>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryInvoicesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryListNftSignatureInfoArgs = {
  signer: Scalars['String'];
  chainId: Scalars['BigInt'];
  collection: Scalars['String'];
  tokenId: Scalars['BigInt'];
  price: Scalars['BigInt'];
  amount: Scalars['Int'];
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryNftListingArgs = {
  key: Scalars['String'];
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryNodeArgs = {
  id: Scalars['ID'];
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryPreviewInvoiceArgs = {
  billingInterval: BillingInterval;
  couponCode?: Maybe<Scalars['String']>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryPublicFileArgs = {
  key: Scalars['String'];
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryPublicUploadArgs = {
  key: Scalars['String'];
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QuerySearchArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FileSearchOrderField>;
  folders?: Maybe<Array<Scalars['ID']>>;
  collections?: Maybe<Array<Scalars['ID']>>;
  ownerEmails?: Maybe<Array<Scalars['String']>>;
  ownerIds?: Maybe<Array<Scalars['ID']>>;
  types?: Maybe<Array<FileType>>;
  tags?: Maybe<Array<Scalars['String']>>;
  detectedLabels?: Maybe<Array<Scalars['String']>>;
  query?: Maybe<Scalars['String']>;
  folderInclusion?: Maybe<Inclusion>;
  deletedInclusion?: Maybe<Inclusion>;
  createdOn?: Maybe<DateFilter>;
  modifiedOn?: Maybe<DateFilter>;
  originalCreatedOn?: Maybe<DateFilter>;
  fields?: Maybe<Array<FieldFilter>>;
  isNft?: Maybe<Scalars['Boolean']>;
  mintedBy?: Maybe<MintedByFilter>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QuerySearchSuggestionsArgs = {
  folders?: Maybe<Array<Scalars['ID']>>;
  collections?: Maybe<Array<Scalars['ID']>>;
  ownerEmails?: Maybe<Array<Scalars['String']>>;
  ownerIds?: Maybe<Array<Scalars['ID']>>;
  types?: Maybe<Array<FileType>>;
  tags?: Maybe<Array<Scalars['String']>>;
  detectedLabels?: Maybe<Array<Scalars['String']>>;
  query?: Maybe<Scalars['String']>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryShareArgs = {
  key?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QuerySharesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryStorageHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryStorageUsageArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryTagsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  sort?: Maybe<TagOrderField>;
  order?: Maybe<Order>;
  query?: Maybe<Scalars['String']>;
  types?: Maybe<Array<TagType>>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryTenantsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  meta?: Maybe<TenantMetaFilter>;
  active?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<TenantOrder>;
  email?: Maybe<TenantEmailFilter>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryUserArgs = {
  email: Scalars['String'];
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  sort?: Maybe<UserOrderField>;
  order?: Maybe<Order>;
  query?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<UserStatus>>;
  billingTypes?: Maybe<Array<UserBillingType>>;
};


/**
 * Fields for retrieving data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type QueryWalletArgs = {
  address: Scalars['String'];
};

export type AccessTokenConnection = {
  __typename?: 'AccessTokenConnection';
  /** A list of nodes. */
  nodes: Array<AccessToken>;
  /** A list of edges. */
  edges: Array<AccessTokenEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AccessToken = Node & {
  __typename?: 'AccessToken';
  id: Scalars['ID'];
  /** Identifies the primary key from the database */
  databaseId: Scalars['String'];
  title: Scalars['String'];
  lastUsedOn?: Maybe<Scalars['Date']>;
};

export type AccessTokenEdge = {
  __typename?: 'AccessTokenEdge';
  node: AccessToken;
  cursor: Scalars['String'];
};

export enum CollectionOrderField {
  Title = 'TITLE',
  CreatedOn = 'CREATED_ON',
  Position = 'POSITION',
  Rank = 'RANK'
}

export type FieldConnection = {
  __typename?: 'FieldConnection';
  /** A list of nodes. */
  nodes: Array<Field>;
  /** A list of edges. */
  edges: Array<FieldEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FieldEdge = {
  __typename?: 'FieldEdge';
  node: Field;
  cursor: Scalars['String'];
};

export enum FieldOrderField {
  Name = 'NAME'
}

export type FileActivity = {
  __typename?: 'FileActivity';
  date: Scalars['Date'];
  fileCount: Scalars['Int'];
  files: FileConnection;
};


export type FileActivityFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FileSearchOrderField>;
};

export enum FileDateField {
  CreatedOn = 'CREATED_ON',
  ModifiedOn = 'MODIFIED_ON',
  OriginalCreatedOn = 'ORIGINAL_CREATED_ON'
}

export enum Inclusion {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE',
  Only = 'ONLY'
}

export type CreateZipBackgroundJob = Node & BackgroundJob & {
  __typename?: 'CreateZipBackgroundJob';
  id: Scalars['ID'];
  state: BackgroundJobState;
  /** Will not be returned once job is in succeeded state */
  downloadUrl?: Maybe<Scalars['String']>;
  zipSize?: Maybe<Scalars['BigInt']>;
};


export type CreateZipBackgroundJobDownloadUrlArgs = {
  title?: Maybe<Scalars['String']>;
};

export type BackgroundJob = {
  id: Scalars['ID'];
  state: BackgroundJobState;
};

export enum BackgroundJobState {
  Queued = 'QUEUED',
  InProgress = 'IN_PROGRESS',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
  Dead = 'DEAD'
}

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** A list of nodes. */
  nodes: Array<Invoice>;
  /** A list of edges. */
  edges: Array<InvoiceEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  node: Invoice;
  cursor: Scalars['String'];
};

export type ListNftSignatureInfo = {
  __typename?: 'ListNftSignatureInfo';
  message: Scalars['JSON'];
  primaryType: Scalars['String'];
  types: Scalars['JSON'];
  domain: Scalars['JSON'];
};

export type Membership = {
  __typename?: 'Membership';
  userId: Scalars['ID'];
  /** @deprecated Replaced by permissions */
  role: UserRole;
  permissions: Array<UserPermission>;
  tenant: Tenant;
};

export type PublicUpload = Node & {
  __typename?: 'PublicUpload';
  id: Scalars['ID'];
  /** Identifies the primary key from the database */
  databaseId: Scalars['String'];
  key: Scalars['String'];
  title: Scalars['String'];
  folder?: Maybe<File>;
  tenant: Tenant;
  isDisabled: Scalars['Boolean'];
  filesUploaded: Scalars['Int'];
  createdOn: Scalars['Date'];
  modifiedOn: Scalars['Date'];
  disabledOn?: Maybe<Scalars['Date']>;
  archivedOn?: Maybe<Scalars['Date']>;
  userDatabaseId: Scalars['String'];
  createdBy: User;
  modifiedBy: User;
};

export type PublicUploadConnection = {
  __typename?: 'PublicUploadConnection';
  /** A list of nodes. */
  nodes: Array<PublicUpload>;
  /** A list of edges. */
  edges: Array<PublicUploadEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PublicUploadEdge = {
  __typename?: 'PublicUploadEdge';
  node: PublicUpload;
  cursor: Scalars['String'];
};

export type DateFilter = {
  after?: Maybe<Scalars['Date']>;
  before?: Maybe<Scalars['Date']>;
};

export type FieldFilter = {
  fieldId: Scalars['ID'];
  valueId?: Maybe<Scalars['ID']>;
  valueContains?: Maybe<Scalars['String']>;
  booleanValue?: Maybe<Scalars['Boolean']>;
  date?: Maybe<DateFilter>;
  isSet?: Maybe<Scalars['Boolean']>;
};

export type MintedByFilter = {
  viewer?: Maybe<Scalars['Boolean']>;
  all?: Maybe<Scalars['Boolean']>;
  userIds?: Maybe<Array<Scalars['ID']>>;
};

export type SearchSuggestions = {
  __typename?: 'SearchSuggestions';
  collections: Array<Collection>;
  owners: Array<User>;
  folders: Array<File>;
};


export type SearchSuggestionsCollectionsArgs = {
  query?: Maybe<Scalars['String']>;
};


export type SearchSuggestionsOwnersArgs = {
  query?: Maybe<Scalars['String']>;
};


export type SearchSuggestionsFoldersArgs = {
  query?: Maybe<Scalars['String']>;
};

export type Share = Node & {
  __typename?: 'Share';
  id: Scalars['ID'];
  /** Identifies the primary key from the database */
  databaseId: Scalars['String'];
  url: Scalars['String'];
  key: Scalars['String'];
  canDownload: Scalars['Boolean'];
  shortId: Scalars['String'];
  tenantName: Scalars['String'];
  createdOn: Scalars['Date'];
  createdBy: User;
  modifiedOn: Scalars['Date'];
  sharePassword?: Maybe<Scalars['String']>;
  modifiedBy: User;
  files: ShareFileConnection;
};


export type ShareFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<ShareFileOrderField>;
  fileType?: Maybe<FileType>;
  fileId?: Maybe<Scalars['String']>;
};

export type ShareFileConnection = {
  __typename?: 'ShareFileConnection';
  /** A list of nodes. */
  nodes: Array<File>;
  /** A list of edges. */
  edges: Array<ShareFileEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  batchKey: Scalars['String'];
  aggregations: FileAggregations;
};

export type ShareFileEdge = {
  __typename?: 'ShareFileEdge';
  node: File;
  cursor: Scalars['String'];
  createdOn: Scalars['Date'];
  modifiedOn: Scalars['Date'];
  createdBy: User;
  modifiedBy: User;
};

export enum ShareFileOrderField {
  FileTitle = 'FILE_TITLE',
  FileSize = 'FILE_SIZE',
  FileId = 'FILE_ID',
  FileCreatedOn = 'FILE_CREATED_ON'
}

export type ShareConnection = {
  __typename?: 'ShareConnection';
  /** A list of nodes. */
  nodes: Array<Share>;
  /** A list of edges. */
  edges: Array<ShareEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ShareEdge = {
  __typename?: 'ShareEdge';
  node: Share;
  cursor: Scalars['String'];
};

export type StorageUsageConnection = {
  __typename?: 'StorageUsageConnection';
  /** A list of nodes. */
  nodes: Array<StorageUsage>;
  /** A list of edges. */
  edges: Array<StorageUsageEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type StorageUsage = {
  __typename?: 'StorageUsage';
  periodStart: Scalars['Date'];
  periodEnd: Scalars['Date'];
  maxStorage: Scalars['BigInt'];
  maxStorageDate: Scalars['Date'];
  amountCharged?: Maybe<Currency>;
};

export type StorageUsageEdge = {
  __typename?: 'StorageUsageEdge';
  node: StorageUsage;
  cursor: Scalars['String'];
};

export type TagConnection = {
  __typename?: 'TagConnection';
  /** A list of nodes. */
  nodes: Array<Tag>;
  /** A list of edges. */
  edges: Array<TagEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type Tag = Node & {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
  fileCount: Scalars['Int'];
  fileThumbnails: Array<Maybe<FileThumbnail>>;
  files: FileConnection;
};


export type TagFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  sort?: Maybe<FileSearchOrderField>;
};

export type TagEdge = {
  __typename?: 'TagEdge';
  node: Tag;
  cursor: Scalars['String'];
};

export enum TagOrderField {
  Tag = 'TAG',
  Count = 'COUNT'
}

export enum TagType {
  User = 'USER',
  Detected = 'DETECTED'
}

export type Tier = {
  __typename?: 'Tier';
  name: Scalars['String'];
  individual?: Maybe<PlanIntervals>;
  group?: Maybe<PlanIntervals>;
};

export type PlanIntervals = {
  __typename?: 'PlanIntervals';
  monthly: Plan;
  yearly: Plan;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of nodes. */
  nodes: Array<User>;
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  node: User;
  cursor: Scalars['String'];
};

export enum UserOrderField {
  Date = 'DATE',
  Name = 'NAME'
}

export type WalletGql = {
  __typename?: 'WalletGQL';
  address: Scalars['String'];
  chainId: Scalars['BigInt'];
  chain: WalletChain;
};

/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type Mutation = {
  __typename?: 'Mutation';
  /** *Does not require access token or x-tenant-id header* */
  acceptInvite: AcceptInvitePayload;
  /** Accept the TOS for the current viewer */
  acceptTermsOfService: AcceptTermsOfServicePayload;
  addCollection: AddCollectionPayload;
  addCollectionFiles: AddCollectionFilesPayload;
  addCollectionTags: AddCollectionTagsPayload;
  addFileTags: AddFileTagsPayload;
  addFolder: AddFolderPayload;
  addListFieldValues: AddListFieldValuesPayload;
  addUsersToCollection: AddUsersToCollectionPayload;
  addUsersToFolder: AddUsersToFolderPayload;
  addWallet: AddWalletPayload;
  /** *Requires admin token* */
  adminCreateTenant: AdminCreateTenantPayload;
  /**
   * Permanently delete a tenant and all associated data
   * 
   * *Requires admin token*
   */
  adminDeleteTenant: DeleteTenantPayload;
  /** *Requires admin token* */
  adminDisableTenant: DisableTenantPayload;
  /** *Requires admin token* */
  adminEmptyTrash: AdminEmptyTrashPayload;
  archivePublicUpload: ArchivePublicUploadPayload;
  /** *Requires admin token* */
  changeUserEmail: ChangeUserEmailPayload;
  /** *Requires admin token* */
  clearCache: ClearCachePayload;
  /** *Does not require access token or x-tenant-id header* */
  confirmEmailChange: ConfirmEmailChangePayload;
  createAccessToken: CreateAccessTokenPayload;
  /** *Requires admin token* */
  createBackgroundJob: CreateBackgroundJobPayload;
  createField: CreateFieldPayload;
  createPublicUpload: CreatePublicUploadPayload;
  createShare: CreateSharePayload;
  createSignedUpload: CreateSignedUploadPayload;
  /** *Does not require x-tenant-id header* */
  createTenant: CreateTenantPayload;
  /** *Does not require access token* */
  createZip: CreateZipPayload;
  deleteAccessToken: DeleteAccessTokenPayload;
  deleteField: DeleteFieldPayload;
  deleteFiles: DeleteFilesPayload;
  deleteShare: DeleteSharePayload;
  deleteTags: DeleteTagsPayload;
  /** Permanently delete a tenant and all associated data */
  deleteTenant: DeleteTenantPayload;
  deleteUserInvite: DeleteUserInvitePayload;
  disableMFA: DisableMfaPayload;
  disableUser: DisableUserPayload;
  emptyTrash: EmptyTrashPayload;
  enableMFA: EnableMfaPayload;
  finalizeSignedUpload: FinalizeSignedUploadPayload;
  importNfts: ImportNftsPayload;
  inviteUsers: InviteUsersPayload;
  listNft: ListNftPayload;
  mintNft: MintNftPayload;
  moveFiles: MoveFilesPayload;
  putFieldEntries: PutFieldEntriesPayload;
  removeAllFieldsFromFiles: RemoveAllFieldsFromFilesPayload;
  removeCollection: RemoveCollectionPayload;
  removeCollectionFiles: RemoveCollectionFilesPayload;
  removeCollectionTags: RemoveCollectionTagsPayload;
  removeCollections: RemoveCollectionsPayload;
  removeFieldEntries: RemoveFieldEntriesPayload;
  removeFieldFromFiles: RemoveFieldFromFilesPayload;
  removeFileDetectedLabels: RemoveFileDetectedLabelsPayload;
  removeFileTags: RemoveFileTagsPayload;
  removeListFieldValues: RemoveListFieldValuesPayload;
  removeUsersFromCollection: RemoveUsersFromCollectionPayload;
  removeUsersFromFolder: RemoveUsersFromFolderPayload;
  renameFile: RenameFilePayload;
  renameTags: RenameTagsPayload;
  /** *Does not require access token or x-tenant-id header* */
  requestPasswordChange: RequestPasswordChangePayload;
  /** *Does not require x-tenant-id header* */
  resendEmailVerification: ResendEmailVerificationPayload;
  resendUsersInvitations: ResendUsersInvitationsPayload;
  restoreFiles: RestoreFilesPayload;
  restoreTrash: RestoreTrashPayload;
  /** *Does not require access token or x-tenant-id header* */
  sendMfaCode: SendMfaCodePayload;
  setCollectionFilePosition: SetCollectionFilePositionPayload;
  /** *Requires admin token* */
  setTrialEnd: SetTrialEndPayload;
  setUserStatus: SetUserStatusPayload;
  /** *Requires role of OWNER* */
  subscribe: SubscribePayload;
  /**
   * Transfer ownership to specified user
   * 
   * *Requires admin token*
   */
  transferOwnership: TransferOwnershipPayload;
  /** *Requires admin token* */
  unblockUser: UnblockUserPayload;
  updateCollection: UpdateCollectionPayload;
  updateField: UpdateFieldPayload;
  updateFieldValue: UpdateFieldValuePayload;
  updateFile: UpdateFilePayload;
  /** *Requires admin token* */
  updateIndices: UpdateIndicesPayload;
  updatePublicUpload: UpdatePublicUploadPayload;
  updateShare: UpdateSharePayload;
  /** *Requires role of OWNER* */
  updateSubscription: UpdateSubscriptionPayload;
  /** *Requires role of OWNER or ADMIN* */
  updateTenantInfo: UpdateTenantInfoPayload;
  updateUser: UpdateUserPayload;
  /** *Does not require access token or x-tenant-id header* */
  validateMfaCode: ValidateMfaCodePayload;
  /** *Requires admin token* */
  wipeTenantData: WipeTenantDataPayload;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAcceptInviteArgs = {
  input: AcceptInviteInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAddCollectionArgs = {
  input: AddCollectionInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAddCollectionFilesArgs = {
  input: AddCollectionFilesInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAddCollectionTagsArgs = {
  input: AddCollectionTagsInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAddFileTagsArgs = {
  input: AddFileTagsInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAddFolderArgs = {
  input: AddFolderInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAddListFieldValuesArgs = {
  input: AddListFieldValuesInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAddUsersToCollectionArgs = {
  input: AddUsersToCollectionInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAddUsersToFolderArgs = {
  input: AddUsersToFolderInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAddWalletArgs = {
  input: AddWalletInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAdminCreateTenantArgs = {
  input: AdminCreateTenantInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAdminDeleteTenantArgs = {
  input: DeleteTenantInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAdminDisableTenantArgs = {
  input: DisableTenantInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationAdminEmptyTrashArgs = {
  input: AdminEmptyTrashInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationArchivePublicUploadArgs = {
  input: ArchivePublicUploadInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationChangeUserEmailArgs = {
  input: ChangeUserEmailInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationClearCacheArgs = {
  input: ClearCacheInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationConfirmEmailChangeArgs = {
  input: ConfirmEmailChangeInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationCreateAccessTokenArgs = {
  input: CreateAccessTokenInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationCreateBackgroundJobArgs = {
  input: CreateBackgroundJobInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationCreateFieldArgs = {
  input: CreateFieldInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationCreatePublicUploadArgs = {
  input: CreatePublicUploadInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationCreateShareArgs = {
  input: CreateShareInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationCreateSignedUploadArgs = {
  input: CreateSignedUploadInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationCreateZipArgs = {
  input: CreateZipInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationDeleteAccessTokenArgs = {
  input: DeleteAccessTokenInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationDeleteFieldArgs = {
  input: DeleteFieldInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationDeleteFilesArgs = {
  input: DeleteFilesInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationDeleteShareArgs = {
  input: DeleteShareInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationDeleteTagsArgs = {
  input: DeleteTagInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationDeleteUserInviteArgs = {
  input: DeleteUserInviteInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationDisableUserArgs = {
  input?: Maybe<DisableUserInput>;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationFinalizeSignedUploadArgs = {
  input: FinalizeSignedUploadInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationImportNftsArgs = {
  input: ImportNftsInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationInviteUsersArgs = {
  input: InviteUsersInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationListNftArgs = {
  input: ListNftInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationMintNftArgs = {
  input: MintNftInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationMoveFilesArgs = {
  input: MoveFilesInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationPutFieldEntriesArgs = {
  input: PutFieldEntriesInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRemoveAllFieldsFromFilesArgs = {
  input: RemoveAllFieldsFromFilesInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRemoveCollectionArgs = {
  input: RemoveCollectionInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRemoveCollectionFilesArgs = {
  input: RemoveCollectionFilesInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRemoveCollectionTagsArgs = {
  input: RemoveCollectionTagsInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRemoveCollectionsArgs = {
  input: RemoveCollectionsInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRemoveFieldEntriesArgs = {
  input: RemoveFieldEntriesInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRemoveFieldFromFilesArgs = {
  input: RemoveFieldFromFilesInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRemoveFileDetectedLabelsArgs = {
  input: RemoveFileDetectedLabelsInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRemoveFileTagsArgs = {
  input: RemoveFileTagsInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRemoveListFieldValuesArgs = {
  input: RemoveListFieldValuesInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRemoveUsersFromCollectionArgs = {
  input: RemoveUsersFromCollectionInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRemoveUsersFromFolderArgs = {
  input: RemoveUsersFromFolderInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRenameFileArgs = {
  input: RenameFileInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRenameTagsArgs = {
  input: RenameTagInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRequestPasswordChangeArgs = {
  input: RequestPasswordChangeInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationResendUsersInvitationsArgs = {
  input: ResendUsersInvitationsInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationRestoreFilesArgs = {
  input: RestoreFilesInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationSendMfaCodeArgs = {
  input: SendMfaCodeInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationSetCollectionFilePositionArgs = {
  input: SetCollectionFilePositionInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationSetTrialEndArgs = {
  input: SetTrialEndInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationSetUserStatusArgs = {
  input: SetUserStatusInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationSubscribeArgs = {
  input: SubscribeInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationTransferOwnershipArgs = {
  input: TransferOwnershipInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationUnblockUserArgs = {
  input: UnblockUserInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationUpdateFieldArgs = {
  input: UpdateFieldInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationUpdateFieldValueArgs = {
  input: UpdateFieldValueInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationUpdateIndicesArgs = {
  input: UpdateIndicesInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationUpdatePublicUploadArgs = {
  input: UpdatePublicUploadInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationUpdateShareArgs = {
  input: UpdateShareInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationUpdateTenantInfoArgs = {
  input: UpdateTenantInfoInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationValidateMfaCodeArgs = {
  input: ValidateMfaCodeInput;
};


/**
 * Fields for modifying data.
 * 
 * Unless specified otherwise, all fields require an access token set in the authorization header and a tenant ID set in the x-tenant-id header.
 */
export type MutationWipeTenantDataArgs = {
  input: WipeTenantDataInput;
};

export type AcceptInvitePayload = {
  __typename?: 'AcceptInvitePayload';
  email: Scalars['String'];
  hasAccount: Scalars['Boolean'];
  tenantId: Scalars['ID'];
  tenantName: Scalars['String'];
};

export type AcceptInviteInput = {
  token: Scalars['String'];
};

export type AcceptTermsOfServicePayload = {
  __typename?: 'AcceptTermsOfServicePayload';
  user: User;
};

export type AddCollectionPayload = {
  __typename?: 'AddCollectionPayload';
  collection: Collection;
};

export type AddCollectionInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
};

export type AddCollectionFilesPayload = {
  __typename?: 'AddCollectionFilesPayload';
  collection: Collection;
  count: Scalars['Int'];
};

export type AddCollectionFilesInput = {
  /** ID of collection to add files to */
  id: Scalars['ID'];
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  fileIds?: Maybe<Array<Scalars['ID']>>;
};

export type AddCollectionTagsPayload = {
  __typename?: 'AddCollectionTagsPayload';
  collection: Collection;
};

export type AddCollectionTagsInput = {
  id: Scalars['ID'];
  tags: Array<Scalars['String']>;
};

export type AddFileTagsPayload = {
  __typename?: 'AddFileTagsPayload';
  count: Scalars['Int'];
};

export type AddFileTagsInput = {
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  ids?: Maybe<Array<Scalars['ID']>>;
  tags: Array<Scalars['String']>;
};

export type AddFolderPayload = {
  __typename?: 'AddFolderPayload';
  /** The created folder */
  folder: File;
};

export type AddFolderInput = {
  title: Scalars['String'];
  /** Optional id of parent folder */
  parentId?: Maybe<Scalars['ID']>;
  /** Optional path to parent folder, will be created if it doesn't exist */
  path?: Maybe<Scalars['String']>;
};

export type AddListFieldValuesPayload = {
  __typename?: 'AddListFieldValuesPayload';
  values: Array<ListFieldValueRecord>;
  field: Field;
};

export type AddListFieldValuesInput = {
  fieldId: Scalars['ID'];
  values: Array<Scalars['String']>;
};

export type AddUsersToCollectionPayload = {
  __typename?: 'AddUsersToCollectionPayload';
  collection: Collection;
};

export type AddUsersToCollectionInput = {
  collectionId: Scalars['ID'];
  users: Array<AddUsersToCollectionUsersInput>;
};

export type AddUsersToCollectionUsersInput = {
  id: Scalars['ID'];
  permissions: Array<CollectionPermission>;
};

export type AddUsersToFolderPayload = {
  __typename?: 'AddUsersToFolderPayload';
  folder: File;
};

export type AddUsersToFolderInput = {
  folderId: Scalars['ID'];
  users: Array<AddUsersToFolderUsersInput>;
};

export type AddUsersToFolderUsersInput = {
  id: Scalars['ID'];
  permissions: Array<FilePermission>;
};

export type AddWalletPayload = {
  __typename?: 'AddWalletPayload';
  /** @deprecated Moved to wallet */
  address: Scalars['String'];
  wallet: WalletGql;
};

export type AddWalletInput = {
  chain: Scalars['BigInt'];
  message: Scalars['String'];
  signature: Scalars['String'];
};

export type AdminCreateTenantPayload = {
  __typename?: 'AdminCreateTenantPayload';
  id: Scalars['ID'];
  databaseId: Scalars['String'];
};

export type AdminCreateTenantInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  owner: CreateTenantOwnerInput;
  referrer?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['ID']>;
  couponCode?: Maybe<Scalars['String']>;
};

export type CreateTenantOwnerInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
};

export type DeleteTenantPayload = {
  __typename?: 'DeleteTenantPayload';
  id: Scalars['ID'];
};

export type DeleteTenantInput = {
  tenantId: Scalars['ID'];
  nameConfirmation: Scalars['String'];
};

export type DisableTenantPayload = {
  __typename?: 'DisableTenantPayload';
  tenant: Tenant;
};

export type DisableTenantInput = {
  tenantId: Scalars['ID'];
};

export type AdminEmptyTrashPayload = {
  __typename?: 'AdminEmptyTrashPayload';
  success: Scalars['Boolean'];
};

export type AdminEmptyTrashInput = {
  tenantId: Scalars['ID'];
};

export type ArchivePublicUploadPayload = {
  __typename?: 'ArchivePublicUploadPayload';
  publicUpload: PublicUpload;
};

export type ArchivePublicUploadInput = {
  id: Scalars['ID'];
};

export type ChangeUserEmailPayload = {
  __typename?: 'ChangeUserEmailPayload';
  email: Scalars['String'];
};

export type ChangeUserEmailInput = {
  tenantId: Scalars['ID'];
  userId: Scalars['ID'];
  email: Scalars['String'];
};

export type ClearCachePayload = {
  __typename?: 'ClearCachePayload';
  tenant?: Maybe<Tenant>;
  plan?: Maybe<Scalars['String']>;
};

export type ClearCacheInput = {
  /** Clears cached customer/subscriptions for tenant */
  tenantId?: Maybe<Scalars['ID']>;
  /** Stripe plan/price ID or "all" */
  plan?: Maybe<Scalars['String']>;
};

export type ConfirmEmailChangePayload = {
  __typename?: 'ConfirmEmailChangePayload';
  email: Scalars['String'];
  previousEmail: Scalars['String'];
};

export type ConfirmEmailChangeInput = {
  token: Scalars['String'];
};

export type CreateAccessTokenPayload = {
  __typename?: 'CreateAccessTokenPayload';
  token: Scalars['String'];
};

export type CreateAccessTokenInput = {
  title: Scalars['String'];
};

export type CreateBackgroundJobPayload = {
  __typename?: 'CreateBackgroundJobPayload';
  key: Scalars['String'];
};

export type CreateBackgroundJobInput = {
  tenantId: Scalars['ID'];
  key: Scalars['String'];
  maxRetries?: Maybe<Scalars['Int']>;
  args?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type CreateFieldPayload = {
  __typename?: 'CreateFieldPayload';
  field: Field;
};

export type CreateFieldInput = {
  name: Scalars['String'];
  type: FieldType;
  permissions?: Maybe<FieldPermissionsInput>;
  isGlobal?: Maybe<Scalars['Boolean']>;
};

export type FieldPermissionsInput = {
  isPublic?: Maybe<Scalars['Boolean']>;
  externalAccess?: Maybe<Scalars['Boolean']>;
};

export type CreatePublicUploadPayload = {
  __typename?: 'CreatePublicUploadPayload';
  publicUpload: PublicUpload;
};

export type CreatePublicUploadInput = {
  title: Scalars['String'];
  folderId?: Maybe<Scalars['ID']>;
};

export type CreateSharePayload = {
  __typename?: 'CreateSharePayload';
  share: Share;
};

export type CreateShareInput = {
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  fileIds?: Maybe<Array<Scalars['ID']>>;
  canDownload?: Maybe<Scalars['Boolean']>;
  sharePassword?: Maybe<Scalars['String']>;
};

export type CreateSignedUploadPayload = {
  __typename?: 'CreateSignedUploadPayload';
  url: Scalars['String'];
  id: Scalars['String'];
};

export type CreateSignedUploadInput = {
  filename: Scalars['String'];
  metadata: Scalars['JSON'];
};

export type CreateTenantPayload = {
  __typename?: 'CreateTenantPayload';
  id: Scalars['ID'];
  databaseId: Scalars['String'];
};

export type CreateTenantInput = {
  name: Scalars['String'];
  owner: CreateTenantOwnerInput;
  referrer?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['ID']>;
  couponCode?: Maybe<Scalars['String']>;
  ifNotExists?: Maybe<Scalars['Boolean']>;
};

export type CreateZipPayload = {
  __typename?: 'CreateZipPayload';
  job: CreateZipBackgroundJob;
};

export type CreateZipInput = {
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** Title for the output zip, not including extension */
  title?: Maybe<Scalars['String']>;
};

export type DeleteAccessTokenPayload = {
  __typename?: 'DeleteAccessTokenPayload';
  success: Scalars['Boolean'];
};

export type DeleteAccessTokenInput = {
  id: Scalars['ID'];
};

export type DeleteFieldPayload = {
  __typename?: 'DeleteFieldPayload';
  success: Scalars['Boolean'];
};

export type DeleteFieldInput = {
  fieldId: Scalars['ID'];
};

export type DeleteFilesPayload = {
  __typename?: 'DeleteFilesPayload';
  count: Scalars['Int'];
};

export type DeleteFilesInput = {
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  ids?: Maybe<Array<Scalars['ID']>>;
  deleteType?: Maybe<DeleteType>;
};

export enum DeleteType {
  Soft = 'SOFT',
  Hard = 'HARD'
}

export type DeleteSharePayload = {
  __typename?: 'DeleteSharePayload';
  ok: Scalars['Boolean'];
};

export type DeleteShareInput = {
  id: Scalars['ID'];
};

export type DeleteTagsPayload = {
  __typename?: 'DeleteTagsPayload';
  tag: Scalars['String'];
};

export type DeleteTagInput = {
  id: Scalars['ID'];
};

export type DeleteUserInvitePayload = {
  __typename?: 'DeleteUserInvitePayload';
  user: User;
};

export type DeleteUserInviteInput = {
  id: Scalars['ID'];
};

export type DisableMfaPayload = {
  __typename?: 'DisableMfaPayload';
  user: User;
};

export type DisableUserPayload = {
  __typename?: 'DisableUserPayload';
  success: Scalars['Boolean'];
};

export type DisableUserInput = {
  id: Scalars['ID'];
};

export type EmptyTrashPayload = {
  __typename?: 'EmptyTrashPayload';
  count: Scalars['Int'];
};

export type EnableMfaPayload = {
  __typename?: 'EnableMfaPayload';
  user: User;
};

export type FinalizeSignedUploadPayload = {
  __typename?: 'FinalizeSignedUploadPayload';
  success: Scalars['Boolean'];
};

export type FinalizeSignedUploadInput = {
  id: Scalars['String'];
};

export type ImportNftsPayload = {
  __typename?: 'ImportNftsPayload';
  job: ImportNftsBackgroundJob;
};

export type ImportNftsBackgroundJob = Node & BackgroundJob & {
  __typename?: 'ImportNftsBackgroundJob';
  id: Scalars['ID'];
  state: BackgroundJobState;
};

export type ImportNftsInput = {
  address: Scalars['String'];
};

export type InviteUsersPayload = {
  __typename?: 'InviteUsersPayload';
  users: Array<User>;
};

export type InviteUsersInput = {
  users: Array<InviteUserFields>;
};

export type InviteUserFields = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  role?: Maybe<UserRole>;
  permissions?: Maybe<Array<UserPermission>>;
};

export type ListNftPayload = {
  __typename?: 'ListNftPayload';
  file?: Maybe<File>;
  listing: NftListing;
};

export type ListNftInput = {
  fileId?: Maybe<Scalars['ID']>;
  order: NftSellOrder;
};

export type NftSellOrder = {
  signature: Scalars['String'];
  signer: Scalars['String'];
  chainId: Scalars['BigInt'];
  collection: Scalars['String'];
  tokenId: Scalars['BigInt'];
  price: Scalars['BigInt'];
  amount: Scalars['Int'];
};

export type MintNftPayload = {
  __typename?: 'MintNftPayload';
  job: MintNftBackgroundJob;
};

export type MintNftBackgroundJob = Node & BackgroundJob & {
  __typename?: 'MintNftBackgroundJob';
  id: Scalars['ID'];
  state: BackgroundJobState;
};

export type MintNftInput = {
  fileId: Scalars['ID'];
  address: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  traits?: Maybe<Array<MintNftTraitsInput>>;
};

export type MintNftTraitsInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type MoveFilesPayload = {
  __typename?: 'MoveFilesPayload';
  count: Scalars['Int'];
};

export type MoveFilesInput = {
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  ids?: Maybe<Array<Scalars['ID']>>;
  folderId?: Maybe<Scalars['ID']>;
};

export type PutFieldEntriesPayload = {
  __typename?: 'PutFieldEntriesPayload';
  count: Scalars['Int'];
};

export type PutFieldEntriesInput = {
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  ids?: Maybe<Array<Scalars['ID']>>;
  valueRecordIds?: Maybe<Array<Scalars['ID']>>;
  values?: Maybe<Array<PutFieldEntriesValuesInput>>;
};

export type PutFieldEntriesValuesInput = {
  fieldId: Scalars['ID'];
  value: Scalars['FieldValue'];
};

export type RemoveAllFieldsFromFilesPayload = {
  __typename?: 'RemoveAllFieldsFromFilesPayload';
  /** Number of files affected */
  count: Scalars['Int'];
};

export type RemoveAllFieldsFromFilesInput = {
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type RemoveCollectionPayload = {
  __typename?: 'RemoveCollectionPayload';
  ok: Scalars['Boolean'];
};

export type RemoveCollectionInput = {
  id: Scalars['ID'];
};

export type RemoveCollectionFilesPayload = {
  __typename?: 'RemoveCollectionFilesPayload';
  collection: Collection;
  count: Scalars['Int'];
};

export type RemoveCollectionFilesInput = {
  /** ID of collection to remove files from */
  id: Scalars['ID'];
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  fileIds?: Maybe<Array<Scalars['ID']>>;
};

export type RemoveCollectionTagsPayload = {
  __typename?: 'RemoveCollectionTagsPayload';
  collection: Collection;
};

export type RemoveCollectionTagsInput = {
  id: Scalars['ID'];
  tags: Array<Scalars['String']>;
};

export type RemoveCollectionsPayload = {
  __typename?: 'RemoveCollectionsPayload';
  ok: Scalars['Boolean'];
};

export type RemoveCollectionsInput = {
  ids: Array<Scalars['ID']>;
};

export type RemoveFieldEntriesPayload = {
  __typename?: 'RemoveFieldEntriesPayload';
  count: Scalars['Int'];
};

export type RemoveFieldEntriesInput = {
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  ids?: Maybe<Array<Scalars['ID']>>;
  valueRecordIds: Array<Scalars['ID']>;
};

export type RemoveFieldFromFilesPayload = {
  __typename?: 'RemoveFieldFromFilesPayload';
  /** Number of files affected */
  count: Scalars['Int'];
  field: Field;
};

export type RemoveFieldFromFilesInput = {
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  ids?: Maybe<Array<Scalars['ID']>>;
  fieldId: Scalars['ID'];
};

export type RemoveFileDetectedLabelsPayload = {
  __typename?: 'RemoveFileDetectedLabelsPayload';
  count: Scalars['Int'];
};

export type RemoveFileDetectedLabelsInput = {
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  ids?: Maybe<Array<Scalars['ID']>>;
  tags: Array<Scalars['String']>;
};

export type RemoveFileTagsPayload = {
  __typename?: 'RemoveFileTagsPayload';
  count: Scalars['Int'];
};

export type RemoveFileTagsInput = {
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** Tags to remove */
  tags: Array<Scalars['String']>;
};

export type RemoveListFieldValuesPayload = {
  __typename?: 'RemoveListFieldValuesPayload';
  field: Field;
};

export type RemoveListFieldValuesInput = {
  fieldId: Scalars['ID'];
  valueIds: Array<Scalars['ID']>;
};

export type RemoveUsersFromCollectionPayload = {
  __typename?: 'RemoveUsersFromCollectionPayload';
  collection: Collection;
};

export type RemoveUsersFromCollectionInput = {
  userIds: Array<Scalars['ID']>;
  collectionId: Scalars['ID'];
};

export type RemoveUsersFromFolderPayload = {
  __typename?: 'RemoveUsersFromFolderPayload';
  folder: File;
};

export type RemoveUsersFromFolderInput = {
  userIds: Array<Scalars['ID']>;
  folderId: Scalars['ID'];
};

export type RenameFilePayload = {
  __typename?: 'RenameFilePayload';
  file: File;
};

export type RenameFileInput = {
  fileId: Scalars['ID'];
  name: Scalars['String'];
};

export type RenameTagsPayload = {
  __typename?: 'RenameTagsPayload';
  tag: Scalars['String'];
};

export type RenameTagInput = {
  id: Scalars['ID'];
  to: Scalars['String'];
};

export type RequestPasswordChangePayload = {
  __typename?: 'RequestPasswordChangePayload';
  success: Scalars['Boolean'];
};

export type RequestPasswordChangeInput = {
  email: Scalars['String'];
};

export type ResendEmailVerificationPayload = {
  __typename?: 'ResendEmailVerificationPayload';
  success: Scalars['Boolean'];
};

export type ResendUsersInvitationsPayload = {
  __typename?: 'ResendUsersInvitationsPayload';
  users: Array<User>;
};

export type ResendUsersInvitationsInput = {
  users: Array<ResendUsersInvitationsFields>;
};

export type ResendUsersInvitationsFields = {
  email: Scalars['String'];
};

export type RestoreFilesPayload = {
  __typename?: 'RestoreFilesPayload';
  count: Scalars['Int'];
};

export type RestoreFilesInput = {
  /** `batchKey` from a FileConnection */
  key?: Maybe<Scalars['String']>;
  /** Array of file IDs */
  ids?: Maybe<Array<Scalars['ID']>>;
  restoreFolderHierarchy?: Maybe<Scalars['Boolean']>;
};

export type RestoreTrashPayload = {
  __typename?: 'RestoreTrashPayload';
  count: Scalars['Int'];
};

export type SendMfaCodePayload = {
  __typename?: 'sendMfaCodePayload';
  challengeType: Scalars['String'];
  oobCode: Scalars['String'];
  bindingMethod: Scalars['String'];
};

export type SendMfaCodeInput = {
  mfaToken: Scalars['String'];
  authenticatorId: Scalars['String'];
};

export type SetCollectionFilePositionPayload = {
  __typename?: 'SetCollectionFilePositionPayload';
  collection: Collection;
};

export type SetCollectionFilePositionInput = {
  collectionId: Scalars['ID'];
  fileId: Scalars['ID'];
  /** Cursor of collection file to move this before. Must be from list sorted by POSITION. */
  before?: Maybe<Scalars['String']>;
  /** Cursor of collection file to move this after. Must be from list sorted by POSITION. */
  after?: Maybe<Scalars['String']>;
};

export type SetTrialEndPayload = {
  __typename?: 'SetTrialEndPayload';
  tenant: Tenant;
  trialEndsOn: Scalars['Date'];
};

export type SetTrialEndInput = {
  tenantId: Scalars['ID'];
  date?: Maybe<Scalars['Date']>;
};

export type SetUserStatusPayload = {
  __typename?: 'SetUserStatusPayload';
  user: User;
};

export type SetUserStatusInput = {
  id: Scalars['ID'];
  status: UserStatus;
};

export type SubscribePayload = {
  __typename?: 'SubscribePayload';
  subscription: Subscription;
  tenant: Tenant;
};

export type SubscribeInput = {
  billingInterval: BillingInterval;
  couponCode?: Maybe<Scalars['String']>;
};

export type TransferOwnershipPayload = {
  __typename?: 'TransferOwnershipPayload';
  tenant: Tenant;
};

export type TransferOwnershipInput = {
  tenantId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type UnblockUserPayload = {
  __typename?: 'UnblockUserPayload';
  success: Scalars['Boolean'];
};

export type UnblockUserInput = {
  email: Scalars['String'];
};

export type UpdateCollectionPayload = {
  __typename?: 'UpdateCollectionPayload';
  collection: Collection;
};

export type UpdateCollectionInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  /** Cursor of collection to move this before. Must be from list sorted by POSITION. */
  before?: Maybe<Scalars['String']>;
  /** Cursor of collection to move this after. Must be from list sorted by POSITION. */
  after?: Maybe<Scalars['String']>;
};

export type UpdateFieldPayload = {
  __typename?: 'UpdateFieldPayload';
  field: Field;
};

export type UpdateFieldInput = {
  fieldId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<FieldPermissionsInput>;
  isGlobal?: Maybe<Scalars['Boolean']>;
};

export type UpdateFieldValuePayload = {
  __typename?: 'UpdateFieldValuePayload';
  value: FieldValueRecord;
};

export type UpdateFieldValueInput = {
  valueId: Scalars['ID'];
  value: Scalars['FieldValue'];
};

export type UpdateFilePayload = {
  __typename?: 'UpdateFilePayload';
  file: File;
};

export type UpdateFileInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  /** DEPRECATED: moved to originalCreatedOn */
  dateCreated?: Maybe<Scalars['Date']>;
  originalCreatedOn?: Maybe<Scalars['Date']>;
  /** If true, sets originalCreatedOn to null (overrides originalCreatedOn if passed at the same time) */
  deleteOriginalCreatedOn?: Maybe<Scalars['Boolean']>;
};

export type UpdateIndicesPayload = {
  __typename?: 'UpdateIndicesPayload';
  success: Scalars['Boolean'];
};

export type UpdateIndicesInput = {
  tenantId: Scalars['ID'];
};

export type UpdatePublicUploadPayload = {
  __typename?: 'UpdatePublicUploadPayload';
  publicUpload: PublicUpload;
};

export type UpdatePublicUploadInput = {
  id: Scalars['ID'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['ID']>;
};

export type UpdateSharePayload = {
  __typename?: 'UpdateSharePayload';
  share: Share;
};

export type UpdateShareInput = {
  id: Scalars['ID'];
  canDownload?: Maybe<Scalars['Boolean']>;
  sharePassword?: Maybe<Scalars['String']>;
};

export type UpdateSubscriptionPayload = {
  __typename?: 'UpdateSubscriptionPayload';
  subscription: Subscription;
  tenant: Tenant;
};

export type UpdateSubscriptionInput = {
  planId: Scalars['ID'];
  /** Coupon code to apply to subscription. If the tenant is currently in a free trial, this will have the same effect as passing endFreeTrial */
  couponCode?: Maybe<Scalars['String']>;
  /** If the tenant is in a free trial, will end the trial and start the subscription. Requires billing info to have already been added. */
  endFreeTrial?: Maybe<Scalars['Boolean']>;
};

export type UpdateTenantInfoPayload = {
  __typename?: 'UpdateTenantInfoPayload';
  tenant: Tenant;
};

export type UpdateTenantInfoInput = {
  general?: Maybe<UpdateTenantInfoGeneralInput>;
  billing?: Maybe<UpdateTenantInfoBillingInput>;
};

export type UpdateTenantInfoGeneralInput = {
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type UpdateTenantInfoBillingInput = {
  token: Scalars['String'];
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user: User;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** Setting this on an active user does not immediately change the email, it is changed after clicking the confirmation link that is sent to the new address. */
  email?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  permissions?: Maybe<Array<UserPermission>>;
};

export type ValidateMfaCodePayload = {
  __typename?: 'validateMfaCodePayload';
  idToken: Scalars['String'];
  accessToken: Scalars['String'];
  expiresIn: Scalars['Int'];
  scope: Scalars['String'];
  tokenType: Scalars['String'];
};

export type ValidateMfaCodeInput = {
  bindingCode: Scalars['String'];
  mfaToken: Scalars['String'];
  oobCode: Scalars['String'];
};

export type WipeTenantDataPayload = {
  __typename?: 'WipeTenantDataPayload';
  tenant: Tenant;
};

export type WipeTenantDataInput = {
  tenantId: Scalars['ID'];
  nameConfirmation: Scalars['String'];
};

export type AccessTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type AccessTokensQuery = (
  { __typename?: 'Query' }
  & { accessTokens: (
    { __typename?: 'AccessTokenConnection' }
    & { nodes: Array<(
      { __typename?: 'AccessToken' }
      & Pick<AccessToken, 'id' | 'title' | 'databaseId' | 'lastUsedOn'>
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type ChangeUserEmailMutationVariables = Exact<{
  input: ChangeUserEmailInput;
}>;


export type ChangeUserEmailMutation = (
  { __typename?: 'Mutation' }
  & { changeUserEmail: (
    { __typename?: 'ChangeUserEmailPayload' }
    & Pick<ChangeUserEmailPayload, 'email'>
  ) }
);

export type ClearCacheMutationVariables = Exact<{
  input: ClearCacheInput;
}>;


export type ClearCacheMutation = (
  { __typename?: 'Mutation' }
  & { clearCache: (
    { __typename?: 'ClearCachePayload' }
    & Pick<ClearCachePayload, 'plan'>
    & { tenant?: Maybe<(
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id'>
    )> }
  ) }
);

export type CreateAccessTokenMutationVariables = Exact<{
  input: CreateAccessTokenInput;
}>;


export type CreateAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & { createAccessToken: (
    { __typename?: 'CreateAccessTokenPayload' }
    & Pick<CreateAccessTokenPayload, 'token'>
  ) }
);

export type CreateBackgroundJobMutationVariables = Exact<{
  input: CreateBackgroundJobInput;
}>;


export type CreateBackgroundJobMutation = (
  { __typename?: 'Mutation' }
  & { createBackgroundJob: (
    { __typename?: 'CreateBackgroundJobPayload' }
    & Pick<CreateBackgroundJobPayload, 'key'>
  ) }
);

export type CreateTenantMutationVariables = Exact<{
  input: AdminCreateTenantInput;
}>;


export type CreateTenantMutation = (
  { __typename?: 'Mutation' }
  & { adminCreateTenant: (
    { __typename?: 'AdminCreateTenantPayload' }
    & Pick<AdminCreateTenantPayload, 'id'>
  ) }
);

export type DeleteAccessTokenMutationVariables = Exact<{
  input: DeleteAccessTokenInput;
}>;


export type DeleteAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & { deleteAccessToken: (
    { __typename?: 'DeleteAccessTokenPayload' }
    & Pick<DeleteAccessTokenPayload, 'success'>
  ) }
);

export type DeleteFilesMutationVariables = Exact<{
  input: DeleteFilesInput;
}>;


export type DeleteFilesMutation = (
  { __typename?: 'Mutation' }
  & { deleteFiles: (
    { __typename?: 'DeleteFilesPayload' }
    & Pick<DeleteFilesPayload, 'count'>
  ) }
);

export type AdminDeleteTenantMutationVariables = Exact<{
  input: DeleteTenantInput;
}>;


export type AdminDeleteTenantMutation = (
  { __typename?: 'Mutation' }
  & { adminDeleteTenant: (
    { __typename?: 'DeleteTenantPayload' }
    & Pick<DeleteTenantPayload, 'id'>
  ) }
);

export type DeletedFilesQueryVariables = Exact<{ [key: string]: never; }>;


export type DeletedFilesQuery = (
  { __typename?: 'Query' }
  & { search: (
    { __typename?: 'FileConnection' }
    & Pick<FileConnection, 'totalCount' | 'batchKey'>
  ) }
);

export type AdminDisableTenantMutationVariables = Exact<{
  input: DisableTenantInput;
}>;


export type AdminDisableTenantMutation = (
  { __typename?: 'Mutation' }
  & { adminDisableTenant: (
    { __typename?: 'DisableTenantPayload' }
    & { tenant: (
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id'>
    ) }
  ) }
);

export type EmptyTrashMutationVariables = Exact<{
  input: AdminEmptyTrashInput;
}>;


export type EmptyTrashMutation = (
  { __typename?: 'Mutation' }
  & { adminEmptyTrash: (
    { __typename?: 'AdminEmptyTrashPayload' }
    & Pick<AdminEmptyTrashPayload, 'success'>
  ) }
);

export type PlansQueryVariables = Exact<{ [key: string]: never; }>;


export type PlansQuery = (
  { __typename?: 'Query' }
  & { plans: Array<(
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'stripeId' | 'name' | 'stripeName' | 'interval' | 'userCapacity'>
  )> }
);

export type RestoreFilesMutationVariables = Exact<{
  input: RestoreFilesInput;
}>;


export type RestoreFilesMutation = (
  { __typename?: 'Mutation' }
  & { restoreFiles: (
    { __typename?: 'RestoreFilesPayload' }
    & Pick<RestoreFilesPayload, 'count'>
  ) }
);

export type SetTrialEndMutationVariables = Exact<{
  input: SetTrialEndInput;
}>;


export type SetTrialEndMutation = (
  { __typename?: 'Mutation' }
  & { setTrialEnd: (
    { __typename?: 'SetTrialEndPayload' }
    & Pick<SetTrialEndPayload, 'trialEndsOn'>
  ) }
);

export type SubscribeMutationVariables = Exact<{
  input: SubscribeInput;
}>;


export type SubscribeMutation = (
  { __typename?: 'Mutation' }
  & { subscribe: (
    { __typename?: 'SubscribePayload' }
    & { tenant: (
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id'>
    ) }
  ) }
);

export type SubscriptionOverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionOverviewQuery = (
  { __typename?: 'Query' }
  & { plans: Array<(
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'stripeId' | 'stripeName' | 'isActive' | 'interval' | 'name'>
    & { tenants: (
      { __typename?: 'TenantConnection' }
      & Pick<TenantConnection, 'totalCount'>
    ) }
  )> }
);

export type TenantQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantQuery = (
  { __typename?: 'Query' }
  & { tenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id' | 'databaseId' | 'name' | 'createdOn' | 'status' | 'totalUsers' | 'meta' | 'stripeCustomer'>
    & { subscription: (
      { __typename?: 'Subscription' }
      & Pick<Subscription, 'isFreeTrial' | 'nextCharge' | 'periodEnd'>
      & { plan: (
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'interval' | 'userCapacityType'>
      ), discount?: Maybe<(
        { __typename?: 'Discount' }
        & Pick<Discount, 'startedOn' | 'endsOn'>
        & { coupon?: Maybe<(
          { __typename?: 'Coupon' }
          & Pick<Coupon, 'name' | 'code'>
        )> }
      )> }
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'databaseId' | 'email'>
    ), capacities: (
      { __typename?: 'TenantCapacities' }
      & Pick<TenantCapacities, 'seats' | 'seatsFilled' | 'seatsAvailable' | 'paidUsers'>
      & { includedStorage: (
        { __typename?: 'StorageSize' }
        & Pick<StorageSize, 'formatted'>
      ), consumedStorage: (
        { __typename?: 'StorageSize' }
        & Pick<StorageSize, 'formatted'>
      ), maxStorage?: Maybe<(
        { __typename?: 'StorageSize' }
        & Pick<StorageSize, 'formatted'>
      )> }
    ) }
  ) }
);

export type TenantContentQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantContentQuery = (
  { __typename?: 'Query' }
  & { search: (
    { __typename?: 'FileConnection' }
    & { aggregations: (
      { __typename?: 'FileAggregations' }
      & Pick<FileAggregations, 'count' | 'size' | 'lastModified' | 'lastCreated'>
    ) }
  ), deleted: (
    { __typename?: 'FileConnection' }
    & Pick<FileConnection, 'totalCount'>
  ) }
);

export type TenantUsersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type TenantUsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserConnection' }
    & Pick<UserConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'databaseId' | 'email' | 'firstName' | 'lastName' | 'status' | 'createdOn' | 'invitedOn' | 'joinedOn'>
      & { tenant: (
        { __typename?: 'Tenant' }
        & Pick<Tenant, 'id'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type TenantsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<TenantOrder>;
  meta?: Maybe<TenantMetaFilter>;
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<TenantEmailFilter>;
}>;


export type TenantsQuery = (
  { __typename?: 'Query' }
  & { tenants: (
    { __typename?: 'TenantConnection' }
    & { nodes: Array<(
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id' | 'databaseId' | 'name' | 'createdOn' | 'meta'>
      & { owner: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type TenantsCountQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  orderBy?: Maybe<TenantOrder>;
  meta?: Maybe<TenantMetaFilter>;
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<TenantEmailFilter>;
}>;


export type TenantsCountQuery = (
  { __typename?: 'Query' }
  & { tenants: (
    { __typename?: 'TenantConnection' }
    & Pick<TenantConnection, 'totalCount'>
  ) }
);

export type TenantsOverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantsOverviewQuery = (
  { __typename?: 'Query' }
  & { active: (
    { __typename?: 'TenantConnection' }
    & Pick<TenantConnection, 'totalCount'>
  ), inactive: (
    { __typename?: 'TenantConnection' }
    & Pick<TenantConnection, 'totalCount'>
  ), trialing: (
    { __typename?: 'TenantConnection' }
    & Pick<TenantConnection, 'totalCount'>
  ), freemium: (
    { __typename?: 'TenantConnection' }
    & Pick<TenantConnection, 'totalCount'>
  ), subscribed: (
    { __typename?: 'TenantConnection' }
    & Pick<TenantConnection, 'totalCount'>
  ), expired: (
    { __typename?: 'TenantConnection' }
    & Pick<TenantConnection, 'totalCount'>
  ), paymentFailed: (
    { __typename?: 'TenantConnection' }
    & Pick<TenantConnection, 'totalCount'>
  ), canceled: (
    { __typename?: 'TenantConnection' }
    & Pick<TenantConnection, 'totalCount'>
  ), disabled: (
    { __typename?: 'TenantConnection' }
    & Pick<TenantConnection, 'totalCount'>
  ) }
);

export type UpdateSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput;
}>;


export type UpdateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateSubscription: (
    { __typename?: 'UpdateSubscriptionPayload' }
    & { tenant: (
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id'>
    ) }
  ) }
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'databaseId' | 'email' | 'firstName' | 'lastName' | 'invitedOn' | 'joinedOn' | 'status' | 'isOwner' | 'permissions' | 'authId' | 'acceptedTerms' | 'acceptedTermsOn' | 'createdOn' | 'modifiedOn'>
    & { createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    ), tenant: (
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id' | 'name'>
    ) }
  ), memberships: Array<(
    { __typename?: 'Membership' }
    & Pick<Membership, 'permissions' | 'userId'>
    & { tenant: (
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id' | 'name'>
    ) }
  )> }
);
