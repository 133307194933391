import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';

export type SearchInputProps<T> = Omit<AutocompleteProps<T, false, false, true>, 'renderInput'>;

function SearchInput<T>(props: SearchInputProps<T>) {
  return (
    <Autocomplete
      {...props}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" size="small" placeholder="Search" />
      )}
      freeSolo
      multiple={false}
      disableClearable={false}
    />
  );
}

export default SearchInput;
