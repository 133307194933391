import React, { useState } from 'react';
import {ChangeUserEmailMutation, ChangeUserEmailMutationVariables, UserQuery} from '../../../apiTypes';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SimpleMenu from '../../../components/SimpleMenu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import { TENANTS } from '../../../Routes';
import PrimaryLink from '../../../components/PrimaryLink';
import CreateAccessTokenDialog from './CreateAccessTokenDialog';
import {useAsyncTasks} from "../../asyncTasks/store";
import raw from "raw.macro";
import {getApiToken} from "../../../utils/getApiToken";
import {useAuth0} from "@auth0/auth0-react";
import {apiRequest} from "../../../utils/apiRequest";
import TextInputDialog from "../../../components/TextInputDialog";

const changeUserEmailQuery = raw('../../../queries/changeUserEmail.graphql');

export type UserToolbarProps = {
  user: UserQuery['user'],
  onRefresh: () => void,
};

export default ({ user, onRefresh }: UserToolbarProps) => {
  const { asyncTaskActions } = useAsyncTasks();
  const { getAccessTokenSilently } = useAuth0();

  const [menuOpen, setMenuOpen] = useState(false);
  const [tokenDialogOpen, setTokenDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);

  const handleTokenMenu = () => {
    setMenuOpen(false);
    setTokenDialogOpen(true);
  }

  const handleEmailMenu = () => {
    setMenuOpen(false);
    setEmailDialogOpen(true);
  };

  const handleChangeEmailConfirm = (value: string) => {
    setEmailDialogOpen(false);
    asyncTaskActions.addTask(`Update email for"${user.email}"`, async () => {
      const accessToken = await getApiToken(getAccessTokenSilently);
      await apiRequest<ChangeUserEmailMutation, ChangeUserEmailMutationVariables>({
        accessToken,
        query: changeUserEmailQuery,
        variables: { input: { tenantId: user.tenant.id, userId: user.id, email: value } },
      });
    });
  };

  return (
    <Toolbar style={{ borderBottom: '1px solid lightgrey' }}>
      <Typography variant="h6" style={{ flexGrow: 1 }}>
        {user.email} in <PrimaryLink to={`${TENANTS}/${user.tenant.id}`}>{user.tenant.name}</PrimaryLink>
      </Typography>
      <SimpleMenu
        open={menuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        buttonContent={<>Actions<ArrowDropDownIcon fontSize="small" /></>}
        buttonProps={{
          color: 'primary',
          variant: 'outlined',
          size: 'small',
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem dense onClick={handleTokenMenu}>
          Create access token
        </MenuItem>
        <MenuItem dense onClick={handleEmailMenu}>
          Change email
        </MenuItem>
      </SimpleMenu>
      <CreateAccessTokenDialog
        tenantId={user.tenant.id}
        userId={user.id}
        open={tokenDialogOpen}
        onSuccess={() => {
          setTokenDialogOpen(false);
          onRefresh();
        }}
        onClose={() => {
          setTokenDialogOpen(false)
        }}
      />
      <TextInputDialog
        open={emailDialogOpen}
        title='Change Email'
        fieldLabel='Email'
        actionLabel='Submit'
        onConfirm={handleChangeEmailConfirm}
        onClose={() => setEmailDialogOpen(false)}
      />
    </Toolbar>
  );
};
