import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import raw from 'raw.macro';
import {
  BillingInterval,
  ClearCacheMutation,
  ClearCacheMutationVariables,
  AdminDeleteTenantMutation,
  AdminDeleteTenantMutationVariables,
  AdminDisableTenantMutation,
  AdminDisableTenantMutationVariables,
  SubscribeMutation,
  SubscribeMutationVariables,
  EmptyTrashMutation,
  EmptyTrashMutationVariables,
  TenantQuery,
} from '../../../apiTypes';
import { useAsyncTasks } from '../../asyncTasks/store';
import { apiRequest } from '../../../utils/apiRequest';
import { useAuth0 } from '@auth0/auth0-react';
import { getApiToken } from '../../../utils/getApiToken';
import SimpleMenu from '../../../components/SimpleMenu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import DestructiveConfirmation from '../../../components/DestructiveConfirmation';
import { useHistory } from 'react-router-dom';
import { TENANTS } from '../../../Routes';
import TrialEndDialog from './TrialEndDialog';
import UpdateSubscriptionDialog from './UpdateSubscriptionDialog';
import Confirmation from '../../../components/Confirmation';
import CreateBackgroundJobDialog from './CreateBackgroundJobDialog';
import RestoreFilesDialog from './RestoreFilesDialog';

const deleteTenantQuery = raw('../../../queries/deleteTenant.graphql');
const disableTenantQuery = raw('../../../queries/disableTenant.graphql');
const clearCacheQuery = raw('../../../queries/clearCache.graphql');
const subscribeQuery = raw('../../../queries/subscribe.graphql');
const emptyTrashQuery = raw('../../../queries/emptyTrash.graphql');

export type TenantToolbarProps = TenantQuery & {
  onRefresh: () => void,
};

export default ({ tenant, onRefresh }: TenantToolbarProps) => {
  const history = useHistory();
  const { asyncTaskActions } = useAsyncTasks();
  const { getAccessTokenSilently } = useAuth0();

  const [menuOpen, setMenuOpen] = useState(false);
  const [trialDialogOpen, setTrialDialogOpen] = useState(false);
  const [updateSubscriptionDialogOpen, setUpdateSubscriptionDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [disableDialogOpen, setDisableDialogOpen] = useState(false);
  const [subscribeDialogOpen, setSubscribeDialogOpen] = useState(false);
  const [backgroundJobDialogOpen, setBackgroundJobDialogOpen] = useState(false);
  const [emptyTrashDialogOpen, setEmptyTrashDialogOpen] = useState(false);
  const [restoreFilesDialogOpen, setRestoreFilesDialogOpen] = useState(false);

  const handleUpdateSubscriptionMenu = () => {
    setMenuOpen(false);
    setUpdateSubscriptionDialogOpen(true);
  };

  const handleDeleteMenu = () => {
    setMenuOpen(false);
    setDeleteDialogOpen(true);
  };

  const handleDisableMenu = () => {
    setMenuOpen(false);
    setDisableDialogOpen(true);
  }

  const handleTrialMenu = () => {
    setMenuOpen(false);
    setTrialDialogOpen(true);
  };

  const handleBackgroundJobMenu = () => {
    setMenuOpen(false);
    setBackgroundJobDialogOpen(true);
  };

  const handleSubscribeMenu = () => {
    setMenuOpen(false);
    setSubscribeDialogOpen(true);
  };

  const handleEmptyTrashMenu = () => {
    setMenuOpen(false);
    setEmptyTrashDialogOpen(true);
  };

  const handleRestoreFilesMenu = () => {
    setMenuOpen(false);
    setRestoreFilesDialogOpen(true);
  };

  const handleClearCacheMenu = () => {
    setMenuOpen(false);
    asyncTaskActions.addTask(`Clear cache for "${tenant.name}"`, async () => {
      const accessToken = await getApiToken(getAccessTokenSilently);
      await apiRequest<ClearCacheMutation, ClearCacheMutationVariables>({
        accessToken,
        query: clearCacheQuery,
        variables: { input: { tenantId: tenant.id } },
      });
    });
  };

  const handleDeleteConfirm = (value: string) => {
    setDeleteDialogOpen(false);
    asyncTaskActions.addTask(`Delete tenant "${tenant.name}"`, async () => {
      const accessToken = await getApiToken(getAccessTokenSilently);
      await apiRequest<AdminDeleteTenantMutation, AdminDeleteTenantMutationVariables>({
        accessToken,
        query: deleteTenantQuery,
        variables: { input: { tenantId: tenant.id, nameConfirmation: value } },
      });
    });
    history.push(TENANTS);
  }

  const handleDisableConfirm = () => {
    setDisableDialogOpen(false);
    asyncTaskActions.addTask(`Disable tenant "${tenant.name}"`, async () => {
      const accessToken = await getApiToken(getAccessTokenSilently);
      await apiRequest<AdminDisableTenantMutation, AdminDisableTenantMutationVariables>({
        accessToken,
        query: disableTenantQuery,
        variables: { input: { tenantId: tenant.id } },
      });
      history.push(TENANTS);
    });
  };

  const handleSubscribeConfirm = () => {
    setSubscribeDialogOpen(false);
    asyncTaskActions.addTask(`Subscribe tenant "${tenant.name}"`, async () => {
      const accessToken = await getApiToken(getAccessTokenSilently);
      await apiRequest<SubscribeMutation, SubscribeMutationVariables>({
        accessToken,
        query: subscribeQuery,
        tenantId: tenant.id,
        variables: { input: { billingInterval: BillingInterval.Month } },
      });
    });
  };

  const handleEmptyTrashConfirm = () => {
    setEmptyTrashDialogOpen(false);
    asyncTaskActions.addTask(`Empty "${tenant.name}" trash`, async () => {
      const accessToken = await getApiToken(getAccessTokenSilently);
      await apiRequest<EmptyTrashMutation, EmptyTrashMutationVariables>({
        accessToken,
        query: emptyTrashQuery,
        variables: { input: { tenantId: tenant.id } },
      });
    });
  };

  return (
    <Toolbar style={{ borderBottom: '1px solid lightgrey' }}>
      <Typography variant="h6" style={{ flexGrow: 1 }}>
        {tenant.name}
      </Typography>
      <SimpleMenu
        open={menuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        buttonContent={<>Actions<ArrowDropDownIcon fontSize="small" /></>}
        buttonProps={{
          color: 'primary',
          variant: 'outlined',
          size: 'small',
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem dense onClick={handleTrialMenu}>
          Set trial end
        </MenuItem>
        <MenuItem dense onClick={handleUpdateSubscriptionMenu}>
          Update subscription
        </MenuItem>
        <MenuItem dense onClick={handleSubscribeMenu}>
          Subscribe
        </MenuItem>
        <MenuItem dense onClick={handleBackgroundJobMenu}>
          Create job
        </MenuItem>
        <MenuItem dense onClick={handleClearCacheMenu}>
          Clear cache
        </MenuItem>
        <MenuItem dense onClick={handleRestoreFilesMenu}>
          Restore Files
        </MenuItem>
        <MenuItem dense onClick={handleEmptyTrashMenu}>
          Empty Trash
        </MenuItem>
        <MenuItem style={{ color: 'red', minWidth: 100 }} dense onClick={handleDisableMenu}>
          Disable
        </MenuItem>
        <MenuItem style={{ color: 'red', minWidth: 100 }} dense onClick={handleDeleteMenu}>
          Delete
        </MenuItem>
      </SimpleMenu>
      <DestructiveConfirmation
        actionLabel="Delete"
        confirmValue={tenant.name}
        onConfirm={handleDeleteConfirm}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
      <Confirmation
        actionLabel="Delete"
        message={`This will empty the trash can for ${tenant.name}. Continue?`}
        onConfirm={handleEmptyTrashConfirm}
        open={emptyTrashDialogOpen}
        onClose={() => setEmptyTrashDialogOpen(false)}
      />
      <Confirmation
        actionLabel="Disable"
        message={`This will disable ${tenant.name}. Continue?`}
        onConfirm={handleDisableConfirm}
        open={disableDialogOpen}
        onClose={() => setDisableDialogOpen(false)}
      />
      <Confirmation
        actionLabel="Subscribe"
        message={`This will subscribe ${tenant.name} to the standard plan. Continue?`}
        onConfirm={handleSubscribeConfirm}
        open={subscribeDialogOpen}
        onClose={() => setSubscribeDialogOpen(false)}
      />
      <TrialEndDialog
        tenantId={tenant.id}
        open={trialDialogOpen}
        onSuccess={() => {
          setTrialDialogOpen(false);
          onRefresh();
        }}
        onClose={() => setTrialDialogOpen(false)}
      />
      <UpdateSubscriptionDialog
        tenantId={tenant.id}
        open={updateSubscriptionDialogOpen}
        onSuccess={() => {
          setUpdateSubscriptionDialogOpen(false);
          onRefresh();
        }}
        onClose={() => setUpdateSubscriptionDialogOpen(false)}
      />
      <CreateBackgroundJobDialog
        tenantId={tenant.id}
        open={backgroundJobDialogOpen}
        onSuccess={() => {
          setBackgroundJobDialogOpen(false);
        }}
        onClose={() => setBackgroundJobDialogOpen(false)}
      />
      <RestoreFilesDialog
        tenantId={tenant.id}
        open={restoreFilesDialogOpen}
        onSuccess={() => {
          setRestoreFilesDialogOpen(false)
        }}
        onClose={() => setRestoreFilesDialogOpen(false)}
      />
    </Toolbar>
  );
}
