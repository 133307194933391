import React from 'react';
import raw from 'raw.macro';
import {Maybe} from "../../../types";
import {useForm} from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Centered } from '../../../utils/ui';
import {CircularProgress, DialogActions} from "@material-ui/core";
import {ControlledTextField} from "../../../components/ControlledTextField";
import Button from "@material-ui/core/Button";
import {useMutation} from "../../../hooks/useMutation";
import {RestoreFilesMutation, RestoreFilesMutationVariables} from "../../../apiTypes";

const restoreFilesQuery = raw('../../../queries/restoreFiles.graphql');

export type RestoreFilesDialogPresentationProps = {
  loading: boolean,
  error: Maybe<string>,
  onSubmit: (data: FormData) => void,
  onClose: () => void,
  open: boolean,
};

export type RestoreFilesDialogProps = {
  tenantId: string,
  onSuccess: () => void,
  onClose: () => void,
  open: boolean,
};

type FormData = {
  ids: string,
};

const RestoreFilesPresentation = (props: RestoreFilesDialogPresentationProps) => {
  const { control, handleSubmit, errors } = useForm<FormData>();

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Restore Files</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        {!!props.error && <DialogContentText color="error">{props.error}</DialogContentText>}
        {props.loading && (
          <Centered>
            <CircularProgress />
          </Centered>
        )}
        {!props.loading && (
          <form id="restore-files-form" onSubmit={handleSubmit(props.onSubmit)}>
            <ControlledTextField
              name="ids"
              label="IDs"
              required
              control={control}
              errors={errors}
            />
          </form>
        )}
      </DialogContent>
      {!props.loading && (
        <DialogActions>
          <Button style={{ color: 'gray' }} onClick={props.onClose}>Cancel</Button>
          <Button type="submit" color="primary" form="restore-files-form">Restore</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default (props: RestoreFilesDialogProps) => {
  const [restoreFiles, { loading, error }] = useMutation<RestoreFilesMutation, RestoreFilesMutationVariables>({
    query: restoreFilesQuery,
    onCompleted: props.onSuccess,
    tenantId: props.tenantId,
  });

  return (
    <RestoreFilesPresentation
      open={props.open}
      loading={loading}
      error={error && 'Error'}
      onClose={props.onClose}
      onSubmit={(data) => {
        restoreFiles({
          variables: {
            input: {
              ids: data.ids
                .split(',')
                .map((s) => s.trim())
                .map((id) => id.startsWith('Rmls') ? id : btoa(`File:${id}`))
            },
          },
        })
      }}
    />
  );
};
