import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import MainLayout from '../../components/MainLayout';
import List from './List';
import Tenant from './Tenant';
import New from './New';

export const TENANTS_NEW = 'new';

export default () => {
  const { path } = useRouteMatch();

  return (
    <MainLayout title="Tenants">
      <Switch>
        <Route exact path={path}>
          <List />
        </Route>
        <Route path={`${path}/${TENANTS_NEW}`}>
          <New />
        </Route>
        <Route path={`${path}/:tenantId`}>
          <Tenant />
        </Route>
      </Switch>
    </MainLayout>
  );
};
