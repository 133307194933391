import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import MinimizeIcon from '@material-ui/icons/Minimize';
import MaximizeIcon from '@material-ui/icons/Maximize';
import ClearIcon from '@material-ui/icons/Clear';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import HourglassIcon from '@material-ui/icons/HourglassEmpty';
import { AsyncTask, AsyncTaskStatus, useAsyncTasks } from './store';
import colors from 'material-colors/dist/colors.json';

const Container = styled(Paper)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 400px;
  z-index: 100;
`;

const TitleContainer = styled.div`
  padding: 6px 16px;
  display: flex;
`;

const ListContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const statusLabelMap: Record<AsyncTaskStatus, string> = {
  [AsyncTaskStatus.Waiting]: 'Waiting',
  [AsyncTaskStatus.InProgress]: 'In Progress',
  [AsyncTaskStatus.Success]: 'Success',
  [AsyncTaskStatus.Error]: 'Error',
};

const canClear = (task: AsyncTask) => task.status === AsyncTaskStatus.Success || task.status === AsyncTaskStatus.Error;

const tasksWithStatus = (tasks: AsyncTask[], status: AsyncTaskStatus) => tasks.filter((task) => task.status === status).length;

export default () => {
  const [minimized, setMinimized] = useState(false);
  const { asyncTaskState, asyncTaskActions } = useAsyncTasks();

  if (!asyncTaskState.tasks.length) {
    return null;
  }

  const isInProgress = !!asyncTaskState.tasks.find((task) => task.status === AsyncTaskStatus.InProgress);

  const succeeded = tasksWithStatus(asyncTaskState.tasks, AsyncTaskStatus.Success);
  const failed = tasksWithStatus(asyncTaskState.tasks, AsyncTaskStatus.Error);

  const clearableTasks = asyncTaskState.tasks.filter(canClear);
  const showClearAll = !minimized && clearableTasks.length > 1;

  return (
    <Container elevation={10}>
      <TitleContainer>
        <Typography variant="subtitle2" display="inline" style={{ marginTop: 6, marginRight: 12 }}>Tasks</Typography>
        <Typography variant="body2" color="textSecondary" display="inline" style={{ marginTop: 6 }}>
          {isInProgress
            ? 'In progress...'
            : [succeeded > 0 ? `${succeeded} Succeeded` : '', failed > 0 ? `${failed} Failed` : '']
              .filter((val) => val)
              .join(', ')
          }
        </Typography>
        <div style={{ flexGrow: 1 }} />
        {showClearAll && (
          <IconButton
            size="small"
            onClick={() => asyncTaskActions.removeTasks(clearableTasks.map(({ id }) => id))}
            style={{ marginRight: 6 }}
          >
            <ClearAllIcon />
          </IconButton>
        )}
        <IconButton size="small" onClick={() => setMinimized(!minimized)}>
          {minimized ? <MaximizeIcon /> : <MinimizeIcon />}
        </IconButton>
      </TitleContainer>
      {!minimized && (<ListContainer>
        <List>
          {asyncTaskState.tasks.map((task) => (
            <ListItem key={task.id} dense>
              <ListItemIcon>
                {task.status === AsyncTaskStatus.Waiting && <HourglassIcon color="primary" />}
                {task.status === AsyncTaskStatus.InProgress && <CircularProgress size={24} />}
                {task.status === AsyncTaskStatus.Success && <CheckIcon style={{ color: colors.green['600'] }} />}
                {task.status === AsyncTaskStatus.Error && <ErrorIcon color="error" />}
              </ListItemIcon>
              <ListItemText
                primary={<div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{task.label}</div>}
                secondary={(<>
                  {statusLabelMap[task.status]}
                  {task.status === AsyncTaskStatus.Error && (<>
                    {' - '}
                    <Typography
                      variant="body2"
                      display="inline"
                      color="primary"
                      onClick={() => asyncTaskActions.retry(task.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      Retry
                    </Typography>
                  </>)}
                </>)}
              />
              {canClear(task) && (
                <ListItemSecondaryAction>
                  <IconButton size="small" edge="end" onClick={() => asyncTaskActions.removeTasks([task.id])}>
                    <ClearIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      </ListContainer>)}
    </Container>
  );
};
