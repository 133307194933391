import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import Button from '@material-ui/core/Button';
import { Maybe } from '../../../types';
import moment, { Moment } from 'moment';
import { useMutation } from '../../../hooks/useMutation';
import { SetTrialEndMutation, SetTrialEndMutationVariables } from '../../../apiTypes';
import raw from 'raw.macro';
import { Centered } from '../../../utils/ui';

const setTrialEndQuery = raw('../../../queries/setTrialEnd.graphql');

export type TrialEndDialogPresentationProps = {
  loading: boolean,
  error: Maybe<string>,
  onSubmit: (date: Maybe<Moment>) => void,
  onClose: () => void,
  open: boolean,
};

export type TrialEndDialogProps = {
  tenantId: string,
  onSuccess: () => void,
  onClose: () => void,
  open: boolean,
}

const TrialEndDialogPresentation = (props: TrialEndDialogPresentationProps) => {
  const [now, setNow] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Maybe<Moment>>(moment().add(1, 'day'));

  const handleNowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNow(event.target.checked);
  };

  const handleDateChange = (date: Moment | null) => {
    setSelectedDate(date);
  };

  const canSubmit = now || selectedDate;

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Set Trial End</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        {!!props.error && <DialogContentText color="error">{props.error}</DialogContentText>}
        {props.loading && (
          <Centered>
            <CircularProgress />
          </Centered>
        )}
        {!props.loading && (
          <FormGroup row>
            <KeyboardDatePicker
              disabled={now}
              disableToolbar
              required
              variant="inline"
              format="MM/DD/yyyy"
              margin="dense"
              label="Date"
              value={selectedDate}
              onChange={handleDateChange}
              style={{ marginRight: 20 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={now}
                  onChange={handleNowChange}
                  name="now"
                  color="primary"
                />
              }
              label="Now"
              style={{ paddingTop: 16 }}
            />
          </FormGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button style={{ color: 'gray' }} onClick={props.onClose}>Cancel</Button>
        <Button
          disabled={!canSubmit}
          color={canSubmit ? 'primary' : 'default'}
          onClick={() => canSubmit && props.onSubmit(now ? null : selectedDate)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default (props: TrialEndDialogProps) => {
  const [setTrialEnd, { loading, error }] = useMutation<SetTrialEndMutation, SetTrialEndMutationVariables>({
    query: setTrialEndQuery,
    onCompleted: props.onSuccess,
  });

  return (
    <TrialEndDialogPresentation
      open={props.open}
      loading={loading}
      error={error && 'Error'}
      onSubmit={(date) => {
        setTrialEnd({
          variables: {
            input: { tenantId: props.tenantId, date: date && date.toISOString() },
          },
        })
      }}
      onClose={props.onClose}
    />
  );
};
