import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  AdminDeleteTenantMutation, AdminDeleteTenantMutationVariables,
  Maybe, PlansQuery, PlansQueryVariables,
  TenantsCountQuery, TenantsCountQueryVariables,
} from '../../../apiTypes';
import { getApiToken } from '../../../utils/getApiToken';
import { apiRequest } from '../../../utils/apiRequest';
import SearchInput from '../../../components/SearchInput';
import Typography from '@material-ui/core/Typography';
import { UseQueryValue } from '../../../hooks/useQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useAsyncTasks } from '../../asyncTasks/store';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import SimpleMenu from '../../../components/SimpleMenu';
import DestructiveConfirmation from '../../../components/DestructiveConfirmation';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import raw from 'raw.macro';
import { TenantData } from './index';
import PlainLink from '../../../components/PlainLink';
import { TENANTS } from '../../../Routes';
import { TENANTS_NEW } from '../index';
import { getAutocompleteOptions } from './search';

const deleteTenantQuery = raw('../../../queries/deleteTenant.graphql');

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.background.default,
  },
  search: {
    width: 300,
    backgroundColor: theme.palette.background.paper,
    marginRight: 16,
  },
}));

export type CountRequest = UseQueryValue<TenantsCountQuery, TenantsCountQueryVariables>;
export type PlansRequest = UseQueryValue<PlansQuery, PlansQueryVariables>;

const Count = ({ request }: { request: CountRequest }) => {
  return (
    <Typography variant="subtitle1" style={{ marginRight: 4 }}>
      {(() => {
        if (request.loading) {
          return 'Loading...';
        }

        if (request.error) {
          return 'Error';
        }

        if (request.data) {
          return `${request.data.tenants.totalCount} results`;
        }
      })()}
    </Typography>
  );
};

type SelectionActionsProps = {
  selected: TenantData[],
  onClear: () => void,
  onDeleted: (ids: string[]) => void,
};

const SelectionActions = (props: SelectionActionsProps) => {
  const { selected, onClear, onDeleted } = props;

  const { getAccessTokenSilently } = useAuth0();
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { asyncTaskActions } = useAsyncTasks();

  if (!selected.length) {
    return null;
  }

  const handleDeleteMenu = () => {
    setMenuOpen(false);
    setDeleteDialogOpen(true);
  }

  const handleDeleteConfirm = () => {
    setDeleteDialogOpen(false);
    onDeleted(selected.map(({ id }) => id));
    for (const tenant of selected) {
      asyncTaskActions.addTask(`Delete tenant "${tenant.name}"`, async () => {
        const accessToken = await getApiToken(getAccessTokenSilently);
        await apiRequest<AdminDeleteTenantMutation, AdminDeleteTenantMutationVariables>({
          accessToken,
          query: deleteTenantQuery,
          variables: { input: { tenantId: tenant.id, nameConfirmation: tenant.name } },
        });
      });
    }
  }

  return (
    <>
      <Typography color="primary" style={{ marginRight: 4 }}>
        {selected.length} selected
      </Typography>
      <IconButton size="small" onClick={onClear} color="primary" style={{ marginRight: 12 }}>
        <CancelIcon />
      </IconButton>
      <SimpleMenu
        open={menuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        buttonContent={<>Actions<ArrowDropDownIcon fontSize="small" /></>}
        buttonProps={{
          color: 'primary',
          variant: 'contained',
          size: 'small',
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem style={{ color: 'red', minWidth: 100 }} dense onClick={handleDeleteMenu}>
          Delete
        </MenuItem>
      </SimpleMenu>
      <DestructiveConfirmation
        actionLabel="Delete"
        message={`Deleting ${selected.length} tenant${selected.length > 1 ? 's' : ''}.`}
        confirmValue={selected.length.toString()}
        onConfirm={handleDeleteConfirm}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
    </>
  );
}

export type TenantToolbarProps = {
  initialSearchValue?: Maybe<string>,
  onSearch: (value: string) => void,
  selectedTenants: TenantData[],
  onRefresh: () => void,
  onClearSelection: () => void,
  onDeleted: (ids: string[]) => void,
  countRequest: CountRequest,
  plansRequest: PlansRequest,
};

export default (props: TenantToolbarProps) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(props.initialSearchValue || '');

  return (
    <Toolbar className={classes.toolbar}>
      <SearchInput
        className={classes.search}
        options={getAutocompleteOptions(searchValue, props.plansRequest.data && props.plansRequest.data.plans)}
        value={searchValue}
        disableCloseOnSelect
        onChange={(event, value) => {
          if (!value) {
            setSearchValue('');
            props.onSearch('');
            return;
          }
          const searchValue: string = (value as any).value;
          if (!searchValue.endsWith(':')) {
            props.onSearch((value as any).value);
          }
        }}
        renderOption={(option) => option.label}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.value || '';
        }}
        getOptionDisabled={(option) => !!option.disabled}
        onInputChange={(event, value) => setSearchValue(value)}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            props.onSearch(searchValue);
          }
        }}
      />
      <Count request={props.countRequest} />
      <IconButton onClick={props.onRefresh} style={{ marginRight: 16 }}>
        <RefreshIcon />
      </IconButton>
      <SelectionActions
        selected={props.selectedTenants}
        onClear={props.onClearSelection}
        onDeleted={props.onDeleted}
      />
      <div style={{ flexGrow: 1 }} />
      <PlainLink to={`${TENANTS}/${TENANTS_NEW}`}>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<AddIcon />}
        >
          New
        </Button>
      </PlainLink>
    </Toolbar>
  );
};
