import React from 'react';
import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

export const PropertyListContainer = styled.div`
  margin-bottom: -4px;
`;

export const PropertyListRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`;

const Label = styled(Typography)`
  max-width: 280px;
  min-width: 180px;
  flex-basis: 20%;
  padding-right: 12px;
`;

export const PropertyListLabel: React.FunctionComponent<TypographyProps> = (props) => {
  return <Label {...props} color="textSecondary" variant="body2" />;
};

const Value = styled(Typography)`
  word-break: break-all;
`;

export const PropertyListValue: React.FunctionComponent<TypographyProps & { isNull?: boolean }> = (props) => {
  const { isNull, ...rest } = props;
  return <Value {...rest} color={props.isNull ? 'textSecondary' : 'textPrimary'} variant="body2" />;
};

export type PropertyListData = {
  label: React.ReactNode,
  value: React.ReactNode,
  nullValue?: React.ReactNode,
};

export type PropertyListProps = {
  data: PropertyListData[],
};

export default (props: PropertyListProps) => (
  <PropertyListContainer>
    {props.data.map((data, i) => (
      <PropertyListRow key={i}>
        <PropertyListLabel>{data.label}</PropertyListLabel>
        <PropertyListValue isNull={data.value == null}>{data.value == null ? data.nullValue || 'null' : data.value}</PropertyListValue>
      </PropertyListRow>
    ))}
  </PropertyListContainer>
);
