import React, { PropsWithChildren } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { CenteredHorizontally } from '../utils/ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Order } from '../apiTypes';
import PlainLink from './PlainLink';

export type DataTableColumn<TData, TOrderBy = any> = {
  key: keyof TData,
  sortKey?: TOrderBy,
  label: string,
  style?: React.CSSProperties,
  render?: (data: TData) => React.ReactNode,
};

export type DataTableOrderBy<TOrderBy> = {
  direction: Order,
  field: TOrderBy,
};

export type DataTableProps<TData extends { selected?: boolean }, TOrderBy = any> = {
  columns: Array<DataTableColumn<TData>>,
  data: Array<TData>,
  hasMore: boolean,
  loadMore: () => Promise<void>,
  getKey: (data: TData) => string,
  orderBy?: DataTableOrderBy<TOrderBy>,
  onOrderByChange?: (orderBy: DataTableOrderBy<TOrderBy>) => void,
  dense?: boolean,
  canClickRows?: boolean,
  onRowClick?: (data: TData) => void,
  rowLink?: (data: TData) => string | null,
  canSelect?: boolean,
  onSelectClick?: (data: TData) => void,
  canSelectAll?: boolean,
  onSelectAllClick?: () => void,
};

type DataTableRowProps<TData> = Pick<DataTableProps<TData>,
  'canClickRows' | 'onRowClick' | 'canSelect' | 'onSelectClick' | 'columns' | 'dense'
> & {
  link?: string | null,
  item: TData,
};

type DataTableRowType = <TData extends { selected?: boolean }>(props: DataTableRowProps<TData>) => React.ReactElement;

const DataTableRow = React.memo(function <TData extends { selected?: boolean }>(props: DataTableRowProps<TData>) {
  const { link, item } = props;
  const canClick = !!link || props.canClickRows;

  return (
    <TableRow
      hover={canClick}
      onClick={() => {
        if (props.onRowClick) {
          props.onRowClick(item);
        }
      }}
    >
      {props.canSelect && (
        <TableCell padding="checkbox">
          <Checkbox
            checked={!!item.selected}
            onChange={() => props.onSelectClick && props.onSelectClick(item)}
          />
        </TableCell>
      )}
      {props.columns.map((column) => (
        <TableCell key={column.key.toString()} style={column.style}>
          {(() => {
            const content = column.render
              ? column.render(item)
              : item[column.key];

            if (link) {
              const padding = props.dense ? '6px 24px 6px 16px' : '16px';
              const margin = props.dense ? '-6px -24px -6px -16px' : '-16px';
              return <PlainLink to={link} style={{ display: 'block', padding, margin }}>{content}</PlainLink>;
            }

            return content;
          })()}
        </TableCell>
      ))}
    </TableRow>
  );
}) as DataTableRowType;

function DataTable<TData extends ({} | { selected?: boolean }), TOrderBy>(props: PropsWithChildren<DataTableProps<TData, TOrderBy>>) {

  return (
    <TableContainer style={{ height: '100%', overflowX: 'auto' }}>
      <InfiniteScroll
        style={{ width: '100%' }}
        pageStart={0}
        loader={(
          <CenteredHorizontally key="progress" style={{ padding: 8 }}>
            <CircularProgress />
          </CenteredHorizontally>
        )}
        useWindow={false}
        loadMore={props.loadMore}
        hasMore={props.hasMore}
      >
        <Table stickyHeader size={props.dense ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              {props.canSelect && (
                <TableCell padding="checkbox">
                  {(props.canSelectAll && props.data.length > 0) && (
                    <Checkbox
                      checked={false}
                      onChange={props.onSelectAllClick}
                    />
                  )}
                </TableCell>
              )}
              {props.columns.map((column) => (
                <TableCell
                  key={column.key.toString()}
                  style={column.style}
                >
                  {props.orderBy && column.sortKey
                    ? (
                      <TableSortLabel
                        active={props.orderBy.field === column.sortKey}
                        direction={props.orderBy.field === column.sortKey ? (props.orderBy.direction === Order.Asc ? 'asc' : 'desc') : 'asc'}
                        onClick={() => {
                          const isCurrentAndAsc = props.orderBy!.field === column.sortKey &&
                            props.orderBy!.direction === Order.Asc;
                          const direction = isCurrentAndAsc ? Order.Desc : Order.Asc;
                          if (props.onOrderByChange) {
                            props.onOrderByChange({
                              field: column.sortKey!,
                              direction,
                            });
                          }
                        }}
                      >
                        {column.label}
                      </TableSortLabel>
                    )
                    : column.label
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((item) => (
              <DataTableRow<TData>
                key={props.getKey(item)}
                item={item}
                columns={props.columns}
                dense={props.dense}
                canSelect={props.canSelect}
                canClickRows={props.canClickRows}
                onRowClick={props.onRowClick}
                link={props.rowLink && props.rowLink(item)}
                onSelectClick={props.onSelectClick}
              />
            ))}
          </TableBody>
        </Table>
      </InfiniteScroll>
    </TableContainer>
  );
};

export default DataTable;
