import React from 'react';
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Centered } from '../../../utils/ui';
import raw from 'raw.macro';
import { Maybe } from '../../../types';
import { ControlledTextField } from '../../../components/ControlledTextField';
import { useMutation } from '../../../hooks/useMutation';
import { CreateBackgroundJobMutation, CreateBackgroundJobMutationVariables } from '../../../apiTypes';

const createBackgroundJobQuery = raw('../../../queries/createBackgroundJob.graphql');

export type CreateBackgroundJobDialogPresentationProps = {
  loading: boolean,
  error: Maybe<string>,
  onSubmit: (data: FormData) => void,
  onClose: () => void,
  open: boolean,
};

export type CreateBackgroundJobDialogProps = {
  tenantId: string,
  onSuccess: () => void,
  onClose: () => void,
  open: boolean,
};

type FormData = {
  key: string,
  args: string,
  meta: string,
};

const isJSON = (value: string) => {
  if (!value) {
    return true;
  }
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
}

const CreateBackgroundJobPresentation = (props: CreateBackgroundJobDialogPresentationProps) => {
  const { control, handleSubmit, errors } = useForm<FormData>();

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Create Background Job</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        {!!props.error && <DialogContentText color="error">{props.error}</DialogContentText>}
        {props.loading && (
          <Centered>
            <CircularProgress />
          </Centered>
        )}
        {!props.loading && (
          <form id="create-background-job-form" onSubmit={handleSubmit(props.onSubmit)}>
            <ControlledTextField
              name="key"
              label="Key"
              control={control}
              errors={errors}
              required
            />
            <ControlledTextField
              name="args"
              label="Args"
              control={control}
              errors={errors}
              required
              validate={isJSON}
              multiline
            />
            <ControlledTextField
              name="meta"
              label="Meta"
              control={control}
              errors={errors}
              validate={isJSON}
              multiline
            />
          </form>
        )}
      </DialogContent>
      {!props.loading && (
        <DialogActions>
          <Button style={{ color: 'gray' }} onClick={props.onClose}>Cancel</Button>
          <Button
            type="submit"
            color="primary"
            form="create-background-job-form"
          >
            Submit
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
};

export default (props: CreateBackgroundJobDialogProps) => {
  const [createBackgroundJob, { loading, error }] = useMutation<CreateBackgroundJobMutation, CreateBackgroundJobMutationVariables>({
    query: createBackgroundJobQuery,
    onCompleted: props.onSuccess,
  });

  return (
    <CreateBackgroundJobPresentation
      open={props.open}
      loading={loading}
      error={error && 'Error'}
      onSubmit={(data) => {
        createBackgroundJob({
          variables: {
            input: {
              tenantId: props.tenantId,
              key: data.key,
              args: JSON.parse(data.args),
              meta: JSON.parse(data.meta),
            },
          },
        })
      }}
      onClose={props.onClose}
    />
  );
};
