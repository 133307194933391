import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export type LayoutProps = {
  direction?: 'horizontal' | 'vertical',
  leadingComponent?: React.ReactNode,
}

const Layout: React.FunctionComponent<LayoutProps> = ({ children, leadingComponent, direction }) => {
  const Container = direction === 'horizontal' ? HorizontalContainer : VerticalContainer;
  return (
    <Container>
      {leadingComponent}
      <Content>
        {children}
      </Content>
    </Container>
  );
};

export default Layout;
