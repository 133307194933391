import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export type DestructiveConfirmationDialogProps = {
  open: boolean,
  message?: string,
  confirmValue: string,
  onConfirm: (text: string) => void,
  onClose: () => void,
  actionLabel: string,
};

export default (props: DestructiveConfirmationDialogProps) => {
  const [value, setValue] = useState('');

  const handleConfirm = () => {
    if (value === props.confirmValue) {
      props.onConfirm(value);
    }
  }

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        <DialogContentText>{props.message ? <>{props.message}<br /><br /></> : ''}This cannot be undone. Type "{props.confirmValue}" to continue.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          onChange={(event) => setValue(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button style={{ color: 'gray' }} onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={handleConfirm}
          style={{ ...value === props.confirmValue && { color: 'red' } }}
          disabled={value !== props.confirmValue}
        >{props.actionLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};
