export type ApiRequestOptions<TVariables extends Record<string, any> = Record<string, any>> = RequestInit & {
  accessToken: string,
  query: string,
  tenantId?: string,
  userId?: string,
  variables?: TVariables,
};

export const apiRequest = async <
  TData extends Record<string, any> = Record<string, any>,
  TVariables extends Record<string, any> = Record<string, any>,
>(options: ApiRequestOptions<TVariables>): Promise<TData> => {
  const { accessToken, query, variables, tenantId, ...fetchOptions } = options;
  const result = await fetch(process.env.REACT_APP_API_URL, {
    ...fetchOptions,
    method: 'POST',
    body: JSON.stringify({
      query: query,
      ...variables && { variables },
    }),
    headers: {
      ...fetchOptions.headers,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      ...options.tenantId && { 'x-tenant-id': options.tenantId },
      ...options.userId && { 'x-user-id': options.userId },
    },
  });
  const { data, errors } = await result.json();
  if (errors) {
    throw new Error(`GraphQL errors: \n${JSON.stringify(errors)}`);
  }

  return data;
}
