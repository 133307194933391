import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export default styled(NavLink)`
  text-decoration: none;
  color: inherit;
  
  &.active * {
    font-weight: 800 !important;
  }
  
  :visited {
    text-decoration: none;
  }
`;
