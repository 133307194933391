import React, { useState, useCallback, useRef } from 'react';
import {
  Maybe,
  Order, PlansQuery, PlansQueryVariables,
  Tenant, TenantEmailOperator,
  TenantOrderField, TenantsCountQuery, TenantsCountQueryVariables,
  TenantsQuery,
  TenantsQueryVariables,
} from '../../../apiTypes';
import DataTable, { DataTableColumn, DataTableOrderBy } from '../../../components/DataTable';
import raw from 'raw.macro';
import moment from 'moment';
import Layout from '../../../components/Layout';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { TENANTS } from '../../../Routes';
import { HUMAN_FULL_DATE_FORMAT } from '../../../utils/constants';
import { titleCase } from 'title-case';
import TenantsToolbar from './TenantsToolbar';
import { useQuery } from '../../../hooks/useQuery';
import { searchFilters } from './search';

const tenantsQuery = raw('../../../queries/tenants.graphql');
const tenantsCountQuery = raw('../../../queries/tenantsCount.graphql');
const plansQuery = raw('../../../queries/plans.graphql');

export type TenantData = Pick<Tenant, 'id' | 'databaseId' | 'name' | 'createdOn'> & { email: string, status: string, selected?: boolean };

type ListData = {
  tenants: TenantData[],
  endCursor?: Maybe<string>,
  hasNextPage: boolean,
};

const columns: Array<DataTableColumn<TenantData, TenantOrderField>> = [
  { key: 'name', label: 'Name', sortKey: TenantOrderField.Name },
  { key: 'email', label: 'Email' },
  { key: 'status', label: 'Status' },
  { key: 'createdOn', label: 'Created On', sortKey: TenantOrderField.CreatedOn, render: ({ createdOn }) => moment(createdOn).format(HUMAN_FULL_DATE_FORMAT) },
];

const getKey = ({ id }: TenantData) => id;
const rowLink = ({ id }: TenantData) => `${TENANTS}/${id}`;

const filterRegexp = new RegExp(`^(${searchFilters.join('|')}):`);
const parseSearchQuery = (query: Maybe<string>): TenantsQueryVariables => {
  if (!query) {
    return {};
  }

  const words = query.split(' ').filter((v) => v);
  const filterWords = words.filter((word) => word.match(filterRegexp));
  const queryWords = words.filter((word) => !word.match(filterRegexp));

  const filterValues = filterWords.reduce((obj, word) => {
    const [key, value] = word.split(':');
    return {
      ...obj,
      [key]: value,
    };
  }, {} as Record<string, string>);

  return {
    ...filterValues.email && {
      email: {
        operator: TenantEmailOperator.Equal,
        values: filterValues.email.split(','),
      },
    },
    ...filterValues.emailNot && {
      email: {
        operator: TenantEmailOperator.NotEqual,
        values: filterValues.emailNot.split(','),
      },
    },
    ...filterValues.active && { active: filterValues.active === 'true' },
    meta: {
      ...filterValues.status && { status: filterValues.status },
      ...filterValues.plan && { plan: filterValues.plan },
      ...filterValues.coupon && { coupon: filterValues.coupon },
    },
    query: queryWords.join(' '),
  };
};

export default () => {
  const [listData, setListData] = useState<ListData>({
    tenants: [],
    hasNextPage: true,
  });

  const listLoading = useRef(false);

  const history = useHistory();
  const queryParams = useQueryParams();

  const orderBy = {
    field: queryParams.get('orderField') as TenantOrderField || TenantOrderField.CreatedOn,
    direction: queryParams.get('orderDirection') as Order || Order.Desc,
  };

  const variables = {
    first: 50,
    after: listData.endCursor,
    orderBy,
    ...parseSearchQuery(queryParams.get('query')),
  };

  const countRequest = useQuery<TenantsCountQuery, TenantsCountQueryVariables>({
    query: tenantsCountQuery,
    variables: {
      ...variables,
      ...{
        first: undefined,
        after: undefined,
      },
    },
  });

  const plansRequest = useQuery<PlansQuery, PlansQueryVariables>({
    query: plansQuery,
  });

  const handleSelected = useCallback((selected: TenantData) => {
    setListData((data) => ({
      ...data,
      tenants: data.tenants.map((tenant) => {
        if (tenant.id !== selected.id) {
          return tenant;
        }

        return {
          ...tenant,
          selected: !tenant.selected,
        };
      })
    }))
  }, []);

  const handleClearSelection = useCallback(() => setListData((data) => ({
    ...data,
    tenants: data.tenants.map((tenant) => {
      if (!tenant.selected) {
        return tenant;
      }
      return {
        ...tenant,
        selected: false,
      };
    }),
  })), []);

  const handleDeleted = useCallback((ids: string[]) => {
    setListData((data) => ({
      ...data,
      tenants: data.tenants.filter((tenant) => !ids.includes(tenant.id)),
    }))
  }, []);

  const resetList = () => setListData({
    tenants: [],
    hasNextPage: true,
  });

  const handleRefresh = () => {
    countRequest.run();
    resetList();
  };

  const setOrderBy = (orderBy: DataTableOrderBy<TenantOrderField>) => {
    queryParams.set('orderField', orderBy.field);
    queryParams.set('orderDirection', orderBy.direction);
    history.replace(`${TENANTS}?${queryParams.toString()}`);
  };

  const { run } = useQuery<TenantsQuery, TenantsQueryVariables>({
    query: tenantsQuery,
    manual: true,
    onCompleted: (result) => {
      setListData((data) => ({
        tenants: [...data.tenants, ...result.tenants.nodes.map((node) => ({
          ...node,
          email: node.owner.email,
          status: node.meta.status && titleCase(node.meta.status),
        }))],
        endCursor: result.tenants.pageInfo.endCursor || data.endCursor,
        hasNextPage: result.tenants.pageInfo.hasNextPage,
      }));
      listLoading.current = false;
    },
  });

  const selectedTenants = listData.tenants.filter(({ selected }) => selected);

  return (
    <Layout
      leadingComponent={(
        <TenantsToolbar
          initialSearchValue={queryParams.get('query')}
          onClearSelection={handleClearSelection}
          onDeleted={handleDeleted}
          onRefresh={handleRefresh}
          onSearch={(value: string) => {
            if (!value) {
              queryParams.delete('query');
            } else {
              queryParams.set('query', value);
            }
            history.replace(`${TENANTS}?${queryParams.toString()}`);
            resetList();
          }}
          selectedTenants={selectedTenants}
          countRequest={countRequest}
          plansRequest={plansRequest}
        />
      )}
    >
      <DataTable<TenantData, TenantOrderField>
        columns={columns}
        dense={true}
        loadMore={async () => {
          if (!listLoading.current) {
            listLoading.current = true;
            run({ variables });
          }
        }}
        hasMore={listData.hasNextPage}
        data={listData.tenants}
        getKey={getKey}
        orderBy={orderBy}
        rowLink={rowLink}
        onOrderByChange={(newOrderBy) => {
          setOrderBy(newOrderBy);
          resetList();
        }}
        canSelect
        onSelectClick={handleSelected}
      />
    </Layout>
  );
};
