import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AccessTokensQuery,
  AccessTokensQueryVariables,
  DeleteAccessTokenMutation,
  DeleteAccessTokenMutationVariables,
  UserQuery,
  UserQueryVariables,
} from '../../../apiTypes';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropertyList from '../../../components/PropertyList';
import { HUMAN_FULL_DATE_FORMAT } from '../../../utils/constants';
import moment from 'moment';
import raw from 'raw.macro';
import { useQuery } from '../../../hooks/useQuery';
import Layout from '../../../components/Layout';
import { Centered, Scrollable } from '../../../utils/ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserToolbar from './UserToolbar';
import Divider from '@material-ui/core/Divider';
import { Maybe } from '../../../types';
import DataTable, { DataTableColumn } from '../../../components/DataTable';
import { USERS } from '../../../Routes';
import ExternalLink from '../../../components/ExternalLink';
import PrimaryLink from '../../../components/PrimaryLink';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Confirmation from '../../../components/Confirmation';
import { useAsyncTasks } from '../../asyncTasks/store';
import { getApiToken } from '../../../utils/getApiToken';
import { apiRequest } from '../../../utils/apiRequest';
import { useAuth0 } from '@auth0/auth0-react';

const userQuery = raw('../../../queries/user.graphql');
const accessTokensQuery = raw('../../../queries/accessTokens.graphql');
const deleteAccessTokenQuery = raw('../../../queries/deleteAccessToken.graphql');

const Content = styled.div`
  padding: 24px;
`;

const ListContainer = styled.div<{ border?: boolean }>`
  padding-right: 12px;
  ${({ border }) => border ? 'border-right: 1px solid lightgrey;' : '' }
`;

const ListTitle = styled(({ children }) => <Typography color="textPrimary" gutterBottom>{children}</Typography>)`
  font-size: 14px;
`;

type UserInfoProps = {
  userQuery: UserQuery,
}

const humanFormat = (date?: string) => date
  ? moment(date).format(HUMAN_FULL_DATE_FORMAT)
  : null;

const UserInfo = ({ userQuery }: UserInfoProps) => {
  const { user } = userQuery;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <ListContainer border>
          <ListTitle>General</ListTitle>
          <PropertyList
            data={[
              { label: 'ID', value: user.id },
              { label: 'Database ID', value: user.databaseId },
              { label: 'Email', value: user.email },
              { label: 'First Name', value: user.firstName },
              { label: 'Last Name', value: user.lastName },
              { label: 'Created By', value: <PrimaryLink to={`${USERS}/${user.tenant.id}/${user.createdBy.id}`}>{user.createdBy.email}</PrimaryLink> },
              { label: 'Permissions', value: user.permissions.join(', ') },
              { label: 'Status', value: user.status },
              {
                label: 'Auth0',
                value: user.authId
                  ? <ExternalLink href={`https://manage.auth0.com/dashboard/us/digitalrelab/users/${btoa(encodeURIComponent(user.authId))}`}>{user.authId}</ExternalLink>
                  : null
              }
            ]}
          />
        </ListContainer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ListContainer>
          <ListTitle>Dates</ListTitle>
          <PropertyList
            data={[
              { label: 'Created', value: humanFormat(user.createdOn) },
              { label: 'Modified', value: humanFormat(user.modifiedOn) },
              { label: 'Invited', value: humanFormat(user.invitedOn) },
              { label: 'Joined', value: humanFormat(user.joinedOn) },
              { label: 'Accepted Terms', value: humanFormat(user.acceptedTermsOn) },
            ]}
          />
        </ListContainer>
      </Grid>
    </Grid>
  );
};

type AccessTokenData = AccessTokensQuery['accessTokens']['nodes'][0];

type AccessTokensListData = {
  accessTokens: AccessTokenData[],
  endCursor?: Maybe<string>,
  hasNextPage: boolean,
}

const columnStyle: React.CSSProperties = {
  whiteSpace: 'nowrap',
};

const accessTokenGetKey = ({ id }: AccessTokenData) => id;

type MembershipsData = {
  permissions: string,
  tenantId: string,
  tenantName: string,
  userId: string,
};

const membershipsColumns: Array<DataTableColumn<MembershipsData>> = [
  { key: 'tenantName', label: 'Tenant Name' },
  { key: 'permissions', label: 'Permissions' },
];

const membershipsGetKey = ({ tenantId }: MembershipsData) => tenantId;
const membershipsLink = ({ tenantId, userId }: MembershipsData) => `${USERS}/${tenantId}/${userId}`;

export default () => {
  const { tenantId, userId } = useParams();
  const { asyncTaskActions } = useAsyncTasks();
  const { getAccessTokenSilently } = useAuth0();

  const [confirmingAccessToken, setConfirmingAccessToken] = useState<Maybe<AccessTokenData>>(null);

  const handleTokenDeleteConfirm = () => {
    if (!confirmingAccessToken) {
      return;
    }
    setConfirmingAccessToken(null);
    setAccessTokenListData((data) => ({
      ...data,
      accessTokens: data.accessTokens.filter(({ id }) => id !== confirmingAccessToken.id),
    }));
    asyncTaskActions.addTask(`Delete access token ${confirmingAccessToken.id}`, async () => {
      const accessToken = await getApiToken(getAccessTokenSilently);
      await apiRequest<DeleteAccessTokenMutation, DeleteAccessTokenMutationVariables>({
        accessToken,
        tenantId,
        userId,
        query: deleteAccessTokenQuery,
        variables: { input: { id: confirmingAccessToken.id } },
      });
    });
  }

  const accessTokenColumns: Array<DataTableColumn<AccessTokenData>> = [
    {
      key: 'id',
      label: ' ',
      render: (data) => (
        <IconButton onClick={() => setConfirmingAccessToken(data)} size="small">
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      ),
    },
    { key: 'title', label: 'Title', style: columnStyle },
    { key: 'lastUsedOn', label: 'Last Used', style: columnStyle, render: ({ lastUsedOn }) => humanFormat(lastUsedOn) },
    { key: 'id', label: 'ID', style: columnStyle },
    { key: 'databaseId', label: 'Database ID', style: columnStyle },
  ];

  const [accessTokenListData, setAccessTokenListData] = useState<AccessTokensListData>({
    accessTokens: [],
    hasNextPage: true,
  });

  const userRequest = useQuery<UserQuery, UserQueryVariables>({
    tenantId,
    userId,
    query: userQuery,
  });

  const accessTokenListLoading = useRef(false);

  const accessTokensRequest = useQuery<AccessTokensQuery, AccessTokensQueryVariables>({
    query: accessTokensQuery,
    manual: true,
    tenantId,
    userId,
    onCompleted: (result) => {
      setAccessTokenListData((data) => ({
        accessTokens: [...data.accessTokens, ...result.accessTokens.nodes],
        endCursor: result.accessTokens.pageInfo.endCursor || data.endCursor,
        hasNextPage: result.accessTokens.pageInfo.hasNextPage,
      }));
      accessTokenListLoading.current = false;
    },
  });

  useEffect(() => {
    setAccessTokenListData({
      accessTokens: [],
      hasNextPage: true,
      endCursor: undefined,
    });
  }, [userId])

  const refresh = () => {
    userRequest.run();
    setAccessTokenListData({
      accessTokens: [],
      hasNextPage: true,
      endCursor: undefined,
    });
  }

  if (userRequest.loading) {
    return <Centered><CircularProgress /></Centered>;
  }

  if (userRequest.error) {
    return <h1>Error</h1>;
  }

  if (userRequest.data) {
    return (
      <Layout
        leadingComponent={(
          <UserToolbar
            user={userRequest.data.user}
            onRefresh={refresh}
          />
        )}
      >
        <Scrollable>
          <Content>
            <UserInfo
              userQuery={userRequest.data}
            />
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ListContainer>
                  <ListTitle>Access Tokens</ListTitle>
                  <div style={{ height: 200, border: '1px solid lightgrey' }}>
                    <DataTable<AccessTokenData, any>
                      columns={accessTokenColumns}
                      dense={true}
                      loadMore={async () => {
                        if (!accessTokenListLoading.current) {
                          accessTokenListLoading.current = true;
                          accessTokensRequest.run();
                        }
                      }}
                      hasMore={accessTokenListData.hasNextPage}
                      data={accessTokenListData.accessTokens}
                      getKey={accessTokenGetKey}
                    />
                  </div>
                </ListContainer>
              </Grid>
              <Grid item xs={12}>
                <ListContainer>
                  <ListTitle>All Memberships</ListTitle>
                  <div style={{ height: 400, border: '1px solid lightgrey' }}>
                    <DataTable<MembershipsData, any>
                      columns={membershipsColumns}
                      dense={true}
                      loadMore={async () => {}}
                      hasMore={false}
                      data={userRequest.data.memberships.map((data) => ({
                        permissions: data.permissions.join(', '),
                        tenantId: data.tenant.id,
                        tenantName: data.tenant.name,
                        userId: data.userId,
                      }))}
                      getKey={membershipsGetKey}
                      rowLink={membershipsLink}
                    />
                  </div>
                </ListContainer>
              </Grid>
            </Grid>
          </Content>
        </Scrollable>
        <Confirmation
          actionLabel="Delete"
          message={confirmingAccessToken ? `Are you sure you want to delete token "${confirmingAccessToken.title}"?` : ''}
          onConfirm={handleTokenDeleteConfirm}
          open={!!confirmingAccessToken}
          onClose={() => setConfirmingAccessToken(null)}
        />
      </Layout>
    );
  }

  return null;
};
